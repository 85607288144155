:root {
  --avatar-shadow-head-blue: 0px 4px 4px 0px rgba(55, 93, 251, 0.24);
  --avatar-shadow-head-gray: 0px 4px 4px 0px rgba(138, 143, 147, 0.16);
  --avatar-shadow-head-purple: 0px 4px 4px 0px rgba(110, 63, 243, 0.24);
  --avatar-shadow-head-red: 0px 4px 4px 0px rgba(233, 53, 53, 0.24);
  --avatar-shadow-head-teal: 0px 4px 4px 0px rgba(44, 189, 242, 0.24);
  --avatar-shadow-head-yellow: 0px 4px 4px 0px rgba(255, 180, 31, 0.24);
  --avatar-shadow-whole-blue: ;
  --avatar-shadow-whole-gray: ;
  --avatar-shadow-whole-purple: ;
  --avatar-shadow-whole-red: ;
  --avatar-shadow-whole-teal: ;
  --avatar-shadow-whole-yellow: ;
  --bluedarker: rgba(0, 95, 204, 1);
  --bluenormal: rgba(0, 119, 255, 1);
  --button-shadow-fancy-blue: 0px 0px 0px 1px rgba(55, 93, 251, 1), 0px 1px 2px 0px rgba(37, 62, 167, 0.48);
  --button-shadow-fancy-important: 0px 0px 0px 1px rgba(36, 38, 40, 1), 0px 1px 2px 0px rgba(27, 28, 29, 0.48);
  --button-shadow-fancy-purple: 0px 0px 0px 1px rgba(110, 63, 243, 1), 0px 1px 2px 0px rgba(90, 54, 191, 0.48);
  --button-shadow-fancy-red: 0px 0px 0px 1px rgba(233, 53, 53, 1), 0px 1px 2px 0px rgba(175, 29, 29, 0.48);
  --button-shadow-focus-error: 0px 0px 0px 4px rgba(255, 236, 235, 1), 0px 0px 0px 2px rgba(255, 255, 255, 1);
  --button-shadow-focus-important: 0px 0px 0px 4px rgba(228, 229, 231, 1), 0px 0px 0px 2px rgba(255, 255, 255, 1);
  --button-shadow-focus-primary: 0px 0px 0px 4px rgba(235, 241, 255, 1), 0px 0px 0px 2px rgba(255, 255, 255, 1);
  --button-shadow-stroke-error: 0px 1px 2px 0px rgba(233, 53, 53, 0.08);
  --button-shadow-stroke-important: 0px 1px 2px 0px rgba(82, 88, 102, 0.06);
  --button-shadow-stroke-primary: 0px 1px 2px 0px rgba(55, 93, 251, 0.08);
  --docs-label-font-family: "Inter", Helvetica;
  --docs-label-font-size: 18px;
  --docs-label-font-style: normal;
  --docs-label-font-weight: 500;
  --docs-label-letter-spacing: -0.27px;
  --docs-label-line-height: 32px;
  --docs-paragraph-font-family: "Inter", Helvetica;
  --docs-paragraph-font-size: 18px;
  --docs-paragraph-font-style: normal;
  --docs-paragraph-font-weight: 400;
  --docs-paragraph-letter-spacing: -0.27px;
  --docs-paragraph-line-height: 32px;
  --fancy-buttonblue-default: var(--foundations-blue-base);
  --fancy-buttonblue-hover: var(--foundations-blue-base);
  --fancy-buttonblue-pressed: var(--foundations-blue-base);
  --fancy-buttonneutral-default: var(--tokens-bg-surface-700);
  --fancy-buttonneutral-hover: var(--tokens-bg-surface-700);
  --fancy-buttonneutral-pressed: var(--tokens-bg-surface-700);
  --fancy-buttonpurple-default: var(--foundations-purple-base);
  --fancy-buttonpurple-hover: var(--foundations-purple-base);
  --fancy-buttonpurple-pressed: var(--foundations-purple-base);
  --fancy-buttonred-default: var(--foundations-red-base);
  --fancy-buttonred-hover: var(--foundations-red-base);
  --fancy-buttonred-pressed: var(--foundations-red-base);
  --foundations-blue-base: rgba(55, 93, 251, 1);
  --foundations-blue-dark: rgba(37, 62, 167, 1);
  --foundations-blue-darker: rgba(22, 38, 100, 1);
  --foundations-blue-light: rgba(194, 214, 255, 1);
  --foundations-blue-lighter: rgba(235, 241, 255, 1);
  --foundations-green-base: rgba(56, 199, 147, 1);
  --foundations-green-dark: rgba(45, 159, 117, 1);
  --foundations-green-darker: rgba(23, 100, 72, 1);
  --foundations-green-light: rgba(203, 245, 229, 1);
  --foundations-green-lighter: rgba(239, 250, 246, 1);
  --foundations-neutral-0: rgba(255, 255, 255, 1);
  --foundations-neutral-100: rgba(246, 248, 250, 1);
  --foundations-neutral-200: rgba(226, 228, 233, 1);
  --foundations-neutral-300: rgba(205, 208, 213, 1);
  --foundations-neutral-400: rgba(134, 140, 152, 1);
  --foundations-neutral-500: rgba(82, 88, 102, 1);
  --foundations-neutral-600: rgba(49, 53, 63, 1);
  --foundations-neutral-700: rgba(32, 35, 45, 1);
  --foundations-neutral-800: rgba(22, 25, 34, 1);
  --foundations-neutral-900: rgba(10, 13, 20, 1);
  --foundations-orange-base: rgba(241, 123, 44, 1);
  --foundations-orange-dark: rgba(194, 84, 10, 1);
  --foundations-orange-darker: rgba(110, 51, 12, 1);
  --foundations-orange-light: rgba(255, 218, 194, 1);
  --foundations-orange-lighter: rgba(254, 243, 235, 1);
  --foundations-pink-base: rgba(226, 85, 242, 1);
  --foundations-pink-dark: rgba(156, 35, 169, 1);
  --foundations-pink-darker: rgba(98, 15, 108, 1);
  --foundations-pink-light: rgba(249, 194, 255, 1);
  --foundations-pink-lighter: rgba(253, 235, 255, 1);
  --foundations-purple-base: rgba(110, 63, 243, 1);
  --foundations-purple-dark: rgba(90, 54, 191, 1);
  --foundations-purple-darker: rgba(43, 22, 100, 1);
  --foundations-purple-light: rgba(202, 194, 255, 1);
  --foundations-purple-lighter: rgba(238, 235, 255, 1);
  --foundations-red-base: rgba(223, 28, 65, 1);
  --foundations-red-dark: rgba(175, 29, 56, 1);
  --foundations-red-darker: rgba(113, 14, 33, 1);
  --foundations-red-light: rgba(248, 201, 210, 1);
  --foundations-red-lighter: rgba(253, 237, 240, 1);
  --foundations-teal-base: rgba(53, 185, 233, 1);
  --foundations-teal-dark: rgba(31, 135, 173, 1);
  --foundations-teal-darker: rgba(22, 69, 100, 1);
  --foundations-teal-light: rgba(194, 239, 255, 1);
  --foundations-teal-lighter: rgba(235, 250, 255, 1);
  --foundations-yellow-base: rgba(242, 174, 64, 1);
  --foundations-yellow-dark: rgba(180, 120, 24, 1);
  --foundations-yellow-darker: rgba(105, 61, 17, 1);
  --foundations-yellow-light: rgba(251, 223, 177, 1);
  --foundations-yellow-lighter: rgba(254, 247, 236, 1);
  --label-large-font-family: "Inter", Helvetica;
  --label-large-font-size: 18px;
  --label-large-font-style: normal;
  --label-large-font-weight: 500;
  --label-large-letter-spacing: -0.27px;
  --label-large-line-height: 24px;
  --label-medium-font-family: "Inter", Helvetica;
  --label-medium-font-size: 16px;
  --label-medium-font-style: normal;
  --label-medium-font-weight: 500;
  --label-medium-letter-spacing: -0.17600000381469727px;
  --label-medium-line-height: 24px;
  --label-small-font-family: "Inter", Helvetica;
  --label-small-font-size: 14px;
  --label-small-font-style: normal;
  --label-small-font-weight: 500;
  --label-small-letter-spacing: -0.0840000033378601px;
  --label-small-line-height: 20px;
  --label-x-large-font-family: "Inter", Helvetica;
  --label-x-large-font-size: 24px;
  --label-x-large-font-style: normal;
  --label-x-large-font-weight: 500;
  --label-x-large-letter-spacing: -0.36px;
  --label-x-large-line-height: 32px;
  --label-x-small-font-family: "Inter", Helvetica;
  --label-x-small-font-size: 12px;
  --label-x-small-font-style: normal;
  --label-x-small-font-weight: 500;
  --label-x-small-letter-spacing: 0px;
  --label-x-small-line-height: 16px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-19-84-font-family: "Gilroy", Helvetica;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-19-84-font-size: 19.84375px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-19-84-font-style: normal;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-19-84-font-weight: 800;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-19-84-letter-spacing: 0px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-19-84-line-height: 56px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-35-58-font-family: "Gilroy", Helvetica;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-35-58-font-size: 35.578125px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-35-58-font-style: normal;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-35-58-font-weight: 800;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-35-58-letter-spacing: 0px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-35-58-line-height: 39.599998474121094px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-35-72-font-family: "Gilroy", Helvetica;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-35-72-font-size: 35.71875px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-35-72-font-style: normal;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-35-72-font-weight: 800;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-35-72-letter-spacing: 0px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-35-72-line-height: 38px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-35-86-font-family: "Gilroy", Helvetica;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-35-86-font-size: 35.859375px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-35-86-font-style: normal;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-35-86-font-weight: 800;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-35-86-letter-spacing: 0px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-35-86-line-height: 39.599998474121094px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-50-font-family: "Gilroy", Helvetica;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-50-font-size: 50px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-50-font-style: normal;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-50-font-weight: 800;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-50-letter-spacing: 0px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-50-line-height: 78px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-63-25-font-family: "Gilroy", Helvetica;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-63-25-font-size: 63.25px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-63-25-font-style: normal;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-63-25-font-weight: 800;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-63-25-letter-spacing: 0px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-63-25-line-height: 78px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-63-63-font-family: "Gilroy", Helvetica;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-63-63-font-size: 63.625px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-63-63-font-style: normal;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-63-63-font-weight: 800;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-63-63-letter-spacing: 0px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-63-63-line-height: 78px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-63-75-font-family: "Gilroy", Helvetica;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-63-75-font-size: 63.75px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-63-75-font-style: normal;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-63-75-font-weight: 800;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-63-75-letter-spacing: 0px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-63-75-line-height: 78px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-medium-14-font-family: "Inter", Helvetica;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-medium-14-font-size: 14px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-medium-14-font-style: normal;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-medium-14-font-weight: 500;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-medium-14-letter-spacing: 0px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-medium-14-line-height: 16px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-medium-16-font-family: "Inter", Helvetica;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-medium-16-font-size: 16px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-medium-16-font-style: normal;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-medium-16-font-weight: 500;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-medium-16-letter-spacing: 0px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-medium-16-line-height: 16px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-medium-17-font-family: "Inter", Helvetica;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-medium-17-font-size: 17px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-medium-17-font-style: normal;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-medium-17-font-weight: 500;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-medium-17-letter-spacing: 0px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-medium-17-line-height: 24px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-regular-12-font-family: "Inter", Helvetica;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-regular-12-font-size: 12px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-regular-12-font-style: normal;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-regular-12-font-weight: 400;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-regular-12-letter-spacing: 0px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-regular-12-line-height: 12px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-regular-16-font-family: "Inter", Helvetica;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-regular-16-font-size: 16px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-regular-16-font-style: normal;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-regular-16-font-weight: 400;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-regular-16-letter-spacing: 0px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-regular-16-line-height: normal;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-regular-16-underline-font-family: "Inter", Helvetica;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-regular-16-underline-font-size: 16px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-regular-16-underline-font-style: normal;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-regular-16-underline-font-weight: 400;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-regular-16-underline-letter-spacing: 0px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-regular-16-underline-line-height: 24px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-regular-18-font-family: "Inter", Helvetica;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-regular-18-font-size: 18px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-regular-18-font-style: normal;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-regular-18-font-weight: 400;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-regular-18-letter-spacing: 0px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-regular-18-line-height: 24px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-semi-bold-16-font-family: "Inter", Helvetica;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-semi-bold-16-font-size: 16px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-semi-bold-16-font-style: normal;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-semi-bold-16-font-weight: 600;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-semi-bold-16-letter-spacing: 0px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-semi-bold-16-line-height: 24px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-semi-bold-18-font-family: "Inter", Helvetica;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-semi-bold-18-font-size: 18px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-semi-bold-18-font-style: normal;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-semi-bold-18-font-weight: 600;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-semi-bold-18-letter-spacing: 0px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-semi-bold-18-line-height: 24px;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-gilroy-extrabold-19-84-font-family: "Gilroy", Helvetica;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-gilroy-extrabold-19-84-font-size: 19.84375px;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-gilroy-extrabold-19-84-font-style: normal;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-gilroy-extrabold-19-84-font-weight: 800;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-gilroy-extrabold-19-84-letter-spacing: 0px;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-gilroy-extrabold-19-84-line-height: 56px;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-gilroy-extrabold-35-72-font-family: "Gilroy", Helvetica;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-gilroy-extrabold-35-72-font-size: 35.71875px;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-gilroy-extrabold-35-72-font-style: normal;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-gilroy-extrabold-35-72-font-weight: 800;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-gilroy-extrabold-35-72-letter-spacing: 0px;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-gilroy-extrabold-35-72-line-height: 38px;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-gilroy-extrabold-35-86-font-family: "Gilroy", Helvetica;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-gilroy-extrabold-35-86-font-size: 35.859375px;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-gilroy-extrabold-35-86-font-style: normal;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-gilroy-extrabold-35-86-font-weight: 800;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-gilroy-extrabold-35-86-letter-spacing: 0px;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-gilroy-extrabold-35-86-line-height: 38px;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-gilroy-extrabold-63-5-font-family: "Gilroy", Helvetica;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-gilroy-extrabold-63-5-font-size: 63.5px;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-gilroy-extrabold-63-5-font-style: normal;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-gilroy-extrabold-63-5-font-weight: 800;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-gilroy-extrabold-63-5-letter-spacing: 0px;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-gilroy-extrabold-63-5-line-height: 78px;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-medium-14-font-family: "Inter", Helvetica;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-medium-14-font-size: 14px;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-medium-14-font-style: normal;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-medium-14-font-weight: 500;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-medium-14-letter-spacing: 0px;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-medium-14-line-height: 16px;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-medium-16-font-family: "Inter", Helvetica;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-medium-16-font-size: 16px;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-medium-16-font-style: normal;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-medium-16-font-weight: 500;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-medium-16-letter-spacing: 0px;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-medium-16-line-height: 16px;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-font-family: "Inter", Helvetica;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-font-size: 16px;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-font-style: normal;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-font-weight: 400;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-letter-spacing: 0px;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-line-height: normal;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-semi-bold-16-font-family: "Inter", Helvetica;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-semi-bold-16-font-size: 16px;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-semi-bold-16-font-style: normal;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-semi-bold-16-font-weight: 600;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-semi-bold-16-letter-spacing: 0px;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-semi-bold-16-line-height: 32px;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-semi-bold-18-font-family: "Inter", Helvetica;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-semi-bold-18-font-size: 18px;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-semi-bold-18-font-style: normal;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-semi-bold-18-font-weight: 600;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-semi-bold-18-letter-spacing: 0px;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-semi-bold-18-line-height: 24px;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-19-84-font-family: "Gilroy", Helvetica;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-19-84-font-size: 19.84375px;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-19-84-font-style: normal;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-19-84-font-weight: 800;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-19-84-letter-spacing: 0px;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-19-84-line-height: 56px;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-35-58-font-family: "Gilroy", Helvetica;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-35-58-font-size: 35.578125px;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-35-58-font-style: normal;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-35-58-font-weight: 800;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-35-58-letter-spacing: 0px;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-35-58-line-height: 38px;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-35-72-font-family: "Gilroy", Helvetica;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-35-72-font-size: 35.71875px;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-35-72-font-style: normal;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-35-72-font-weight: 800;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-35-72-letter-spacing: 0px;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-35-72-line-height: 38px;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-35-86-font-family: "Gilroy", Helvetica;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-35-86-font-size: 35.859375px;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-35-86-font-style: normal;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-35-86-font-weight: 800;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-35-86-letter-spacing: 0px;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-35-86-line-height: 38px;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-63-63-font-family: "Gilroy", Helvetica;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-63-63-font-size: 63.625px;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-63-63-font-style: normal;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-63-63-font-weight: 800;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-63-63-letter-spacing: 0px;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-63-63-line-height: 78px;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-medium-12-font-family: "Inter", Helvetica;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-medium-12-font-size: 12px;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-medium-12-font-style: normal;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-medium-12-font-weight: 500;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-medium-12-letter-spacing: 0px;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-medium-12-line-height: 16px;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-medium-14-font-family: "Inter", Helvetica;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-medium-14-font-size: 14px;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-medium-14-font-style: normal;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-medium-14-font-weight: 500;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-medium-14-letter-spacing: 0px;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-medium-14-line-height: 16px;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-medium-16-font-family: "Inter", Helvetica;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-medium-16-font-size: 16px;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-medium-16-font-style: normal;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-medium-16-font-weight: 500;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-medium-16-letter-spacing: 0px;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-medium-16-line-height: 16px;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-regular-12-font-family: "Inter", Helvetica;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-regular-12-font-size: 12px;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-regular-12-font-style: normal;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-regular-12-font-weight: 400;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-regular-12-letter-spacing: 0px;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-regular-12-line-height: 20px;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-regular-16-font-family: "Inter", Helvetica;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-regular-16-font-size: 16px;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-regular-16-font-style: normal;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-regular-16-font-weight: 400;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-regular-16-letter-spacing: 0px;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-regular-16-line-height: 24px;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-semi-bold-18-font-family: "Inter", Helvetica;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-semi-bold-18-font-size: 18px;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-semi-bold-18-font-style: normal;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-semi-bold-18-font-weight: 600;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-semi-bold-18-letter-spacing: 0px;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-semi-bold-18-line-height: 24px;
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultathens-gray: rgba(241, 242, 244, 1);
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultbig-stone-6: rgba(22, 37, 70, 0.06);
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultbiloba-flower: rgba(189, 174, 242, 1);
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultblack: rgba(0, 0, 0, 1);
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultbright-gray-5: rgba(56, 56, 82, 0.05);
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultbuttercup: rgba(240, 185, 11, 1);
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultcornflower-blue: rgba(98, 126, 234, 1);
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultdove-gray: rgba(98, 98, 98, 1);
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultdusty-gray: rgba(153, 153, 153, 1);
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultfern: rgba(90, 181, 108, 1);
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultfirefly-8: rgba(15, 36, 56, 0.08);
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultghost: rgba(209, 209, 214, 1);
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultiron: rgba(228, 228, 231, 1);
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultmanatee: rgba(146, 153, 166, 1);
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultmedium-purple: rgba(130, 71, 229, 1);
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultmidnight-blue: rgba(2, 32, 110, 1);
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultnavy-blue: rgba(255, 0, 122, 1);
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultnero: rgba(255, 255, 255, 1);
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultnero-02: rgba(255, 255, 255, 0);
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultnero-4: rgba(255, 255, 255, 0.04);
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultporcelain: rgba(231, 233, 235, 1);
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultportage: rgba(129, 152, 238, 1);
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultsaffron: rgba(246, 204, 64, 1);
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultsantas-gray: rgba(160, 160, 171, 1);
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultscorpion: rgba(90, 90, 90, 1);
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultshark: rgba(38, 39, 43, 1);
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultshuttle-gray: rgba(94, 103, 115, 1);
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultsilver-chalice: rgba(166, 166, 166, 1);
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultsulu: rgba(181, 240, 127, 1);
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaulttropical-blue: rgba(192, 204, 247, 1);
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaulttuna: rgba(49, 53, 60, 1);
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultwhite-lilac: rgba(252, 252, 253, 1);
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultwoodsmoke: rgba(23, 23, 27, 1);
  --matchaxyzcross-chain-swaps1024x1820defaultathens-gray: rgba(241, 242, 244, 1);
  --matchaxyzcross-chain-swaps1024x1820defaultathens-gray-60: rgba(247, 247, 248, 0.6);
  --matchaxyzcross-chain-swaps1024x1820defaultbig-stone-6: rgba(22, 37, 70, 0.06);
  --matchaxyzcross-chain-swaps1024x1820defaultbiloba-flower: rgba(180, 166, 234, 1);
  --matchaxyzcross-chain-swaps1024x1820defaultblack: rgba(0, 0, 0, 1);
  --matchaxyzcross-chain-swaps1024x1820defaultblack-russian: rgba(255, 4, 32, 1);
  --matchaxyzcross-chain-swaps1024x1820defaultblue-marguerite: rgba(95, 99, 195, 1);
  --matchaxyzcross-chain-swaps1024x1820defaultbright-gray-5: rgba(56, 56, 82, 0.05);
  --matchaxyzcross-chain-swaps1024x1820defaultbright-sun: rgba(254, 212, 64, 1);
  --matchaxyzcross-chain-swaps1024x1820defaultbuttercup: rgba(240, 185, 11, 1);
  --matchaxyzcross-chain-swaps1024x1820defaultchantilly: rgba(244, 174, 241, 1);
  --matchaxyzcross-chain-swaps1024x1820defaultchateau-green: rgba(60, 180, 104, 1);
  --matchaxyzcross-chain-swaps1024x1820defaultcinnabar: rgba(232, 65, 66, 1);
  --matchaxyzcross-chain-swaps1024x1820defaultcornflower-blue: rgba(98, 126, 234, 1);
  --matchaxyzcross-chain-swaps1024x1820defaultcrusoe: rgba(2, 83, 255, 1);
  --matchaxyzcross-chain-swaps1024x1820defaultemerald: rgba(60, 189, 104, 1);
  --matchaxyzcross-chain-swaps1024x1820defaultfern: rgba(90, 181, 108, 1);
  --matchaxyzcross-chain-swaps1024x1820defaultfirefly-17: rgba(14, 30, 42, 0.17);
  --matchaxyzcross-chain-swaps1024x1820defaultfirefly-8: rgba(15, 36, 56, 0.08);
  --matchaxyzcross-chain-swaps1024x1820defaultgreen-haze: rgba(2, 168, 99, 1);
  --matchaxyzcross-chain-swaps1024x1820defaultiron: rgba(228, 228, 231, 1);
  --matchaxyzcross-chain-swaps1024x1820defaultlimeade: rgba(121, 175, 255, 1);
  --matchaxyzcross-chain-swaps1024x1820defaultmanatee: rgba(146, 153, 166, 1);
  --matchaxyzcross-chain-swaps1024x1820defaultnero: rgba(255, 255, 255, 1);
  --matchaxyzcross-chain-swaps1024x1820defaultnero-20: rgba(255, 255, 255, 0.2);
  --matchaxyzcross-chain-swaps1024x1820defaultnero-4: rgba(255, 255, 255, 0.04);
  --matchaxyzcross-chain-swaps1024x1820defaultnero-602: rgba(255, 255, 255, 0.6);
  --matchaxyzcross-chain-swaps1024x1820defaultnero-radial-pastel-green-pastel-green: rgba(255, 255, 255, 1);
  --matchaxyzcross-chain-swaps1024x1820defaultperfume: rgba(189, 175, 243, 1);
  --matchaxyzcross-chain-swaps1024x1820defaultpickled-bluewood: rgba(44, 55, 75, 1);
  --matchaxyzcross-chain-swaps1024x1820defaultpicton-blue: rgba(36, 162, 238, 1);
  --matchaxyzcross-chain-swaps1024x1820defaultporcelain: rgba(231, 233, 235, 1);
  --matchaxyzcross-chain-swaps1024x1820defaultpurple-heart: rgba(111, 65, 216, 1);
  --matchaxyzcross-chain-swaps1024x1820defaultregent-st-blue: rgba(152, 188, 220, 1);
  --matchaxyzcross-chain-swaps1024x1820defaultsaffron: rgba(245, 203, 64, 1);
  --matchaxyzcross-chain-swaps1024x1820defaultsantas-gray: rgba(160, 160, 171, 1);
  --matchaxyzcross-chain-swaps1024x1820defaultshark: rgba(34, 38, 42, 1);
  --matchaxyzcross-chain-swaps1024x1820defaultshuttle-gray: rgba(94, 103, 115, 1);
  --matchaxyzcross-chain-swaps1024x1820defaultsulu: rgba(176, 237, 128, 1);
  --matchaxyzcross-chain-swaps1024x1820defaultturquoise-blue: rgba(90, 210, 225, 1);
  --matchaxyzcross-chain-swaps1024x1820defaultwhite-lilac: rgba(252, 252, 253, 1);
  --matchaxyzcross-chain-swaps1024x1820defaultwoodsmoke: rgba(23, 23, 27, 1);
  --matchaxyzcross-chain-swaps1024x1820defaultyellow-green: rgba(172, 232, 127, 1);
  --matchaxyzswap-tokens1024x1820defaultapple: rgba(94, 163, 59, 1);
  --matchaxyzswap-tokens1024x1820defaultathens-gray: rgba(247, 247, 248, 1);
  --matchaxyzswap-tokens1024x1820defaultathens-gray-60: rgba(247, 247, 248, 0.6);
  --matchaxyzswap-tokens1024x1820defaultbig-stone-6: rgba(22, 37, 70, 0.06);
  --matchaxyzswap-tokens1024x1820defaultblack: rgba(0, 0, 0, 1);
  --matchaxyzswap-tokens1024x1820defaultblack-12: rgba(0, 0, 0, 0.12);
  --matchaxyzswap-tokens1024x1820defaultblack-russian: rgba(255, 4, 32, 1);
  --matchaxyzswap-tokens1024x1820defaultbuttercup: rgba(240, 185, 11, 1);
  --matchaxyzswap-tokens1024x1820defaultchateau-green: rgba(57, 161, 80, 1);
  --matchaxyzswap-tokens1024x1820defaultcinnabar: rgba(232, 65, 66, 1);
  --matchaxyzswap-tokens1024x1820defaultcornflower-blue: rgba(98, 126, 234, 1);
  --matchaxyzswap-tokens1024x1820defaultcrusoe: rgba(2, 83, 255, 1);
  --matchaxyzswap-tokens1024x1820defaultfern: rgba(90, 181, 108, 1);
  --matchaxyzswap-tokens1024x1820defaultjapanese-laurel: rgba(25, 105, 255, 1);
  --matchaxyzswap-tokens1024x1820defaultmalachite-6: rgba(0, 203, 32, 0.06);
  --matchaxyzswap-tokens1024x1820defaultmanatee: rgba(146, 153, 166, 1);
  --matchaxyzswap-tokens1024x1820defaultmariner: rgba(39, 117, 202, 1);
  --matchaxyzswap-tokens1024x1820defaultnero: rgba(255, 255, 255, 1);
  --matchaxyzswap-tokens1024x1820defaultnero-20: rgba(255, 255, 255, 0.2);
  --matchaxyzswap-tokens1024x1820defaultnero-4: rgba(255, 255, 255, 0.04);
  --matchaxyzswap-tokens1024x1820defaultnero-602: rgba(255, 255, 255, 0.6);
  --matchaxyzswap-tokens1024x1820defaultperfume: rgba(190, 175, 243, 1);
  --matchaxyzswap-tokens1024x1820defaultpickled-bluewood: rgba(44, 55, 75, 1);
  --matchaxyzswap-tokens1024x1820defaultpicton-blue: rgba(36, 162, 238, 1);
  --matchaxyzswap-tokens1024x1820defaultporcelain: rgba(231, 233, 235, 1);
  --matchaxyzswap-tokens1024x1820defaultpurple-heart: rgba(111, 65, 216, 1);
  --matchaxyzswap-tokens1024x1820defaultregent-st-blue: rgba(152, 188, 220, 1);
  --matchaxyzswap-tokens1024x1820defaultsaffron-mango: rgba(251, 204, 92, 1);
  --matchaxyzswap-tokens1024x1820defaultsantas-gray: rgba(160, 160, 171, 1);
  --matchaxyzswap-tokens1024x1820defaultshamrock: rgba(53, 208, 127, 1);
  --matchaxyzswap-tokens1024x1820defaultshark: rgba(34, 38, 42, 1);
  --matchaxyzswap-tokens1024x1820defaultshuttle-gray: rgba(94, 103, 115, 1);
  --matchaxyzswap-tokens1024x1820defaultsulu: rgba(178, 239, 128, 1);
  --matchaxyzswap-tokens1024x1820defaultwhite-lilac: rgba(252, 252, 253, 1);
  --matchaxyzswap-tokens1024x1820defaultwoodsmoke: rgba(23, 23, 28, 1);
  --modalmodal-overlay: rgba(2, 13, 23, 0.19);
  --neutral-0: rgba(255, 255, 255, 1);
  --neutral-100: rgba(239, 240, 243, 1);
  --neutral-200: rgba(224, 225, 228, 1);
  --neutral-300: rgba(207, 206, 210, 1);
  --neutral-50: rgba(247, 248, 250, 1);
  --neutral-700: rgba(57, 60, 63, 1);
  --neutral-800: rgba(29, 32, 34, 1);
  --neutral-900: rgba(18, 19, 21, 1);
  --paragraph-large-font-family: "Inter", Helvetica;
  --paragraph-large-font-size: 18px;
  --paragraph-large-font-style: normal;
  --paragraph-large-font-weight: 400;
  --paragraph-large-letter-spacing: -0.27px;
  --paragraph-large-line-height: 24px;
  --paragraph-medium-font-family: "Inter", Helvetica;
  --paragraph-medium-font-size: 16px;
  --paragraph-medium-font-style: normal;
  --paragraph-medium-font-weight: 400;
  --paragraph-medium-letter-spacing: -0.17600000381469727px;
  --paragraph-medium-line-height: 24px;
  --paragraph-small-font-family: "Inter", Helvetica;
  --paragraph-small-font-size: 14px;
  --paragraph-small-font-style: normal;
  --paragraph-small-font-weight: 400;
  --paragraph-small-letter-spacing: -0.0840000033378601px;
  --paragraph-small-line-height: 20px;
  --paragraph-x-large-font-family: "Inter", Helvetica;
  --paragraph-x-large-font-size: 24px;
  --paragraph-x-large-font-style: normal;
  --paragraph-x-large-font-weight: 400;
  --paragraph-x-large-letter-spacing: -0.36px;
  --paragraph-x-large-line-height: 32px;
  --paragraph-x-small-font-family: "Inter", Helvetica;
  --paragraph-x-small-font-size: 12px;
  --paragraph-x-small-font-style: normal;
  --paragraph-x-small-font-weight: 400;
  --paragraph-x-small-letter-spacing: 0px;
  --paragraph-x-small-line-height: 16px;
  --pick-theme-primary-base: var(--foundations-blue-base);
  --pick-theme-primary-dark: var(--foundations-blue-dark);
  --pick-theme-primary-darker: var(--foundations-blue-darker);
  --pick-theme-primary-light: var(--foundations-blue-light);
  --pick-theme-primary-lighter: var(--foundations-blue-lighter);
  --radio-checkbox-shadow-active-bg: ;
  --radio-checkbox-shadow-default: 0px 2px 2px 0px rgba(27, 28, 29, 0.12);
  --radio-checkbox-shadow-disabled: ;
  --radio-checkbox-shadow-radio-dot-active: 0px 2px 2px 0px rgba(27, 28, 29, 0.12);
  --reddarker: rgba(188, 16, 16, 1);
  --rednormal: rgba(239, 68, 68, 1);
  --regular-shadow-2x-large: 0px 40px 96px -8px rgba(88, 92, 95, 0.2);
  --regular-shadow-large: 0px 16px 40px -8px rgba(88, 92, 95, 0.16);
  --regular-shadow-medium: 0px 16px 32px -12px rgba(88, 92, 95, 0.1);
  --regular-shadow-small: 0px 2px 4px 0px rgba(27, 28, 29, 0.04);
  --regular-shadow-x-large: 0px 24px 56px -4px rgba(88, 92, 95, 0.16);
  --regular-shadow-x-small: 0px 1px 2px 0px rgba(228, 229, 231, 0.24);
  --small-elements: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  --subheading-2x-small-font-family: "Inter", Helvetica;
  --subheading-2x-small-font-size: 11px;
  --subheading-2x-small-font-style: normal;
  --subheading-2x-small-font-weight: 500;
  --subheading-2x-small-letter-spacing: 0.22px;
  --subheading-2x-small-line-height: 12px;
  --subheading-medium-font-family: "Inter", Helvetica;
  --subheading-medium-font-size: 16px;
  --subheading-medium-font-style: normal;
  --subheading-medium-font-weight: 500;
  --subheading-medium-letter-spacing: 0.96px;
  --subheading-medium-line-height: 24px;
  --subheading-small-font-family: "Inter", Helvetica;
  --subheading-small-font-size: 14px;
  --subheading-small-font-style: normal;
  --subheading-small-font-weight: 500;
  --subheading-small-letter-spacing: 0.84px;
  --subheading-small-line-height: 20px;
  --subheading-x-small-font-family: "Inter", Helvetica;
  --subheading-x-small-font-size: 12px;
  --subheading-x-small-font-style: normal;
  --subheading-x-small-font-weight: 500;
  --subheading-x-small-letter-spacing: 0.48px;
  --subheading-x-small-line-height: 16px;
  --title-h1-title-font-family: "Inter Display", Helvetica;
  --title-h1-title-font-size: 56px;
  --title-h1-title-font-style: normal;
  --title-h1-title-font-weight: 500;
  --title-h1-title-letter-spacing: -0.56px;
  --title-h1-title-line-height: 64px;
  --title-h2-title-font-family: "Inter Display", Helvetica;
  --title-h2-title-font-size: 48px;
  --title-h2-title-font-style: normal;
  --title-h2-title-font-weight: 500;
  --title-h2-title-letter-spacing: -0.48px;
  --title-h2-title-line-height: 56px;
  --title-h3-title-font-family: "Inter Display", Helvetica;
  --title-h3-title-font-size: 40px;
  --title-h3-title-font-style: normal;
  --title-h3-title-font-weight: 500;
  --title-h3-title-letter-spacing: -0.4px;
  --title-h3-title-line-height: 48px;
  --title-h4-title-font-family: "Inter Display", Helvetica;
  --title-h4-title-font-size: 32px;
  --title-h4-title-font-style: normal;
  --title-h4-title-font-weight: 500;
  --title-h4-title-letter-spacing: 0px;
  --title-h4-title-line-height: 40px;
  --title-h5-title-font-family: "Inter Display", Helvetica;
  --title-h5-title-font-size: 24px;
  --title-h5-title-font-style: normal;
  --title-h5-title-font-weight: 500;
  --title-h5-title-letter-spacing: 0px;
  --title-h5-title-line-height: 32px;
  --title-h6-title-font-family: "Inter Display", Helvetica;
  --title-h6-title-font-size: 20px;
  --title-h6-title-font-style: normal;
  --title-h6-title-font-weight: 500;
  --title-h6-title-letter-spacing: 0px;
  --title-h6-title-line-height: 28px;
  --toggle-shadow-switch-toggle-active: 0px 2px 4px 0px rgba(27, 28, 29, 0.02), 0px 6px 10px 0px rgba(27, 28, 29, 0.06);
  --toggle-shadow-toggle-active: 0px 2px 4px 0px rgba(22, 38, 100, 0.08), 0px 4px 8px 0px rgba(22, 38, 100, 0.08),
    0px 6px 10px 0px rgba(22, 38, 100, 0.08);
  --toggle-shadow-toggle-active-bg: ;
  --toggle-shadow-toggle-default: 0px 2px 4px 0px rgba(27, 28, 29, 0.02), 0px 6px 10px 0px rgba(27, 28, 29, 0.06);
  --toggle-shadow-toggle-default-bg: ;
  --tokens-bg-soft-200: var(--foundations-neutral-200);
  --tokens-bg-strong-900: var(--foundations-neutral-900);
  --tokens-bg-surface-700: var(--foundations-neutral-700);
  --tokens-bg-weak-100: var(--foundations-neutral-100);
  --tokens-bg-white-0: var(--foundations-neutral-0);
  --tokens-icon-disabled-300: var(--foundations-neutral-300);
  --tokens-icon-soft-400: var(--foundations-neutral-400);
  --tokens-icon-strong-900: var(--foundations-neutral-900);
  --tokens-icon-sub-500: var(--foundations-neutral-500);
  --tokens-icon-white-0: var(--foundations-neutral-0);
  --tokens-state-away: var(--foundations-yellow-base);
  --tokens-state-error: var(--foundations-red-base);
  --tokens-state-feature: var(--foundations-purple-base);
  --tokens-state-information: var(--foundations-blue-base);
  --tokens-state-neutral: var(--foundations-neutral-400);
  --tokens-state-success: var(--foundations-green-base);
  --tokens-state-verified: var(--foundations-teal-base);
  --tokens-state-warning: var(--foundations-orange-base);
  --tokens-stroke-disabled-100: var(--foundations-neutral-100);
  --tokens-stroke-soft-200: var(--foundations-neutral-200);
  --tokens-stroke-strong-900: var(--foundations-neutral-900);
  --tokens-stroke-sub-300: var(--foundations-neutral-300);
  --tokens-stroke-white-0: var(--foundations-neutral-0);
  --tokens-text-disabled-300: var(--foundations-neutral-300);
  --tokens-text-main-900: var(--foundations-neutral-900);
  --tokens-text-soft-400: var(--foundations-neutral-400);
  --tokens-text-sub-500: var(--foundations-neutral-500);
  --tokens-text-white-0: var(--foundations-neutral-0);
  --tooltip-shadow-default-light: 0px 1px 2px 0px rgba(228, 229, 231, 0.24), 0px 12px 24px 0px rgba(134, 140, 152, 0.12);
  --typography-action-font-family: "Inter", Helvetica;
  --typography-action-font-size: 14px;
  --typography-action-font-style: normal;
  --typography-action-font-weight: 500;
  --typography-action-letter-spacing: -0.14px;
  --typography-action-line-height: 24px;
  --www-google-com-arial-regular-20-8-font-family: "Arial", Helvetica;
  --www-google-com-arial-regular-20-8-font-size: 20.796875px;
  --www-google-com-arial-regular-20-8-font-style: normal;
  --www-google-com-arial-regular-20-8-font-weight: 400;
  --www-google-com-arial-regular-20-8-letter-spacing: 0px;
  --www-google-com-arial-regular-20-8-line-height: 24px;
  --www-google-com-inter-regular-12-font-family: "Inter", Helvetica;
  --www-google-com-inter-regular-12-font-size: 12px;
  --www-google-com-inter-regular-12-font-style: normal;
  --www-google-com-inter-regular-12-font-weight: 400;
  --www-google-com-inter-regular-12-letter-spacing: 0px;
  --www-google-com-inter-regular-12-line-height: 24px;
  --www-google-com-inter-regular-9-13-font-family: "Inter", Helvetica;
  --www-google-com-inter-regular-9-13-font-size: 9.125px;
  --www-google-com-inter-regular-9-13-font-style: normal;
  --www-google-com-inter-regular-9-13-font-weight: 400;
  --www-google-com-inter-regular-9-13-letter-spacing: 0px;
  --www-google-com-inter-regular-9-13-line-height: 16px;
  --www-google-com-inter-regular-9-font-family: "Inter", Helvetica;
  --www-google-com-inter-regular-9-font-size: 9px;
  --www-google-com-inter-regular-9-font-style: normal;
  --www-google-com-inter-regular-9-font-weight: 400;
  --www-google-com-inter-regular-9-letter-spacing: 0px;
  --www-google-com-inter-regular-9-line-height: 18px;
  --www-google-com-roboto-bold-11-upper-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-bold-11-upper-font-size: 11px;
  --www-google-com-roboto-bold-11-upper-font-style: normal;
  --www-google-com-roboto-bold-11-upper-font-weight: 700;
  --www-google-com-roboto-bold-11-upper-letter-spacing: 0.800000011920929px;
  --www-google-com-roboto-bold-11-upper-line-height: 16px;
  --www-google-com-roboto-bold-12-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-bold-12-font-size: 12px;
  --www-google-com-roboto-bold-12-font-style: normal;
  --www-google-com-roboto-bold-12-font-weight: 700;
  --www-google-com-roboto-bold-12-letter-spacing: 0.30000001192092896px;
  --www-google-com-roboto-bold-12-line-height: 16px;
  --www-google-com-roboto-medium-11-upper-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-medium-11-upper-font-size: 11px;
  --www-google-com-roboto-medium-11-upper-font-style: normal;
  --www-google-com-roboto-medium-11-upper-font-weight: 500;
  --www-google-com-roboto-medium-11-upper-letter-spacing: 0.800000011920929px;
  --www-google-com-roboto-medium-11-upper-line-height: 18px;
  --www-google-com-roboto-medium-12-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-medium-12-font-size: 12px;
  --www-google-com-roboto-medium-12-font-style: normal;
  --www-google-com-roboto-medium-12-font-weight: 500;
  --www-google-com-roboto-medium-12-letter-spacing: 0.30000001192092896px;
  --www-google-com-roboto-medium-12-line-height: 16px;
  --www-google-com-roboto-medium-12-upper-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-medium-12-upper-font-size: 12px;
  --www-google-com-roboto-medium-12-upper-font-style: normal;
  --www-google-com-roboto-medium-12-upper-font-weight: 500;
  --www-google-com-roboto-medium-12-upper-letter-spacing: 0.800000011920929px;
  --www-google-com-roboto-medium-12-upper-line-height: 16px;
  --www-google-com-roboto-medium-13-63-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-medium-13-63-font-size: 13.625px;
  --www-google-com-roboto-medium-13-63-font-style: normal;
  --www-google-com-roboto-medium-13-63-font-weight: 500;
  --www-google-com-roboto-medium-13-63-letter-spacing: 0.10000000149011612px;
  --www-google-com-roboto-medium-13-63-line-height: 24px;
  --www-google-com-roboto-medium-13-78-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-medium-13-78-font-size: 13.78125px;
  --www-google-com-roboto-medium-13-78-font-style: normal;
  --www-google-com-roboto-medium-13-78-font-weight: 500;
  --www-google-com-roboto-medium-13-78-letter-spacing: 0.25px;
  --www-google-com-roboto-medium-13-78-line-height: 20px;
  --www-google-com-roboto-medium-13-89-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-medium-13-89-font-size: 13.890625px;
  --www-google-com-roboto-medium-13-89-font-style: normal;
  --www-google-com-roboto-medium-13-89-font-weight: 500;
  --www-google-com-roboto-medium-13-89-letter-spacing: 0.25px;
  --www-google-com-roboto-medium-13-89-line-height: 20px;
  --www-google-com-roboto-medium-14-25-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-medium-14-25-font-size: 14.25px;
  --www-google-com-roboto-medium-14-25-font-style: normal;
  --www-google-com-roboto-medium-14-25-font-weight: 500;
  --www-google-com-roboto-medium-14-25-letter-spacing: 0.10000000149011612px;
  --www-google-com-roboto-medium-14-25-line-height: 24px;
  --www-google-com-roboto-medium-14-38-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-medium-14-38-font-size: 14.375px;
  --www-google-com-roboto-medium-14-38-font-style: normal;
  --www-google-com-roboto-medium-14-38-font-weight: 500;
  --www-google-com-roboto-medium-14-38-letter-spacing: 0.10000000149011612px;
  --www-google-com-roboto-medium-14-38-line-height: 24px;
  --www-google-com-roboto-medium-14-63-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-medium-14-63-font-size: 14.625px;
  --www-google-com-roboto-medium-14-63-font-style: normal;
  --www-google-com-roboto-medium-14-63-font-weight: 500;
  --www-google-com-roboto-medium-14-63-letter-spacing: 0.10000000149011612px;
  --www-google-com-roboto-medium-14-63-line-height: 24px;
  --www-google-com-roboto-medium-14-75-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-medium-14-75-font-size: 14.75px;
  --www-google-com-roboto-medium-14-75-font-style: normal;
  --www-google-com-roboto-medium-14-75-font-weight: 500;
  --www-google-com-roboto-medium-14-75-letter-spacing: 0.10000000149011612px;
  --www-google-com-roboto-medium-14-75-line-height: 24px;
  --www-google-com-roboto-medium-14-88-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-medium-14-88-font-size: 14.875px;
  --www-google-com-roboto-medium-14-88-font-style: normal;
  --www-google-com-roboto-medium-14-88-font-weight: 500;
  --www-google-com-roboto-medium-14-88-letter-spacing: 0.10000000149011612px;
  --www-google-com-roboto-medium-14-88-line-height: 24px;
  --www-google-com-roboto-medium-14-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-medium-14-font-size: 14px;
  --www-google-com-roboto-medium-14-font-style: normal;
  --www-google-com-roboto-medium-14-font-weight: 500;
  --www-google-com-roboto-medium-14-letter-spacing: 0.25px;
  --www-google-com-roboto-medium-14-line-height: 16px;
  --www-google-com-roboto-medium-15-13-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-medium-15-13-font-size: 15.125px;
  --www-google-com-roboto-medium-15-13-font-style: normal;
  --www-google-com-roboto-medium-15-13-font-weight: 500;
  --www-google-com-roboto-medium-15-13-letter-spacing: 0.10000000149011612px;
  --www-google-com-roboto-medium-15-13-line-height: 24px;
  --www-google-com-roboto-medium-15-25-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-medium-15-25-font-size: 15.25px;
  --www-google-com-roboto-medium-15-25-font-style: normal;
  --www-google-com-roboto-medium-15-25-font-weight: 500;
  --www-google-com-roboto-medium-15-25-letter-spacing: 0.10000000149011612px;
  --www-google-com-roboto-medium-15-25-line-height: 24px;
  --www-google-com-roboto-medium-15-38-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-medium-15-38-font-size: 15.375px;
  --www-google-com-roboto-medium-15-38-font-style: normal;
  --www-google-com-roboto-medium-15-38-font-weight: 500;
  --www-google-com-roboto-medium-15-38-letter-spacing: 0.10000000149011612px;
  --www-google-com-roboto-medium-15-38-line-height: 24px;
  --www-google-com-roboto-medium-15-5-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-medium-15-5-font-size: 15.5px;
  --www-google-com-roboto-medium-15-5-font-style: normal;
  --www-google-com-roboto-medium-15-5-font-weight: 500;
  --www-google-com-roboto-medium-15-5-letter-spacing: 0.10000000149011612px;
  --www-google-com-roboto-medium-15-5-line-height: 24px;
  --www-google-com-roboto-medium-15-63-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-medium-15-63-font-size: 15.625px;
  --www-google-com-roboto-medium-15-63-font-style: normal;
  --www-google-com-roboto-medium-15-63-font-weight: 500;
  --www-google-com-roboto-medium-15-63-letter-spacing: 0.10000000149011612px;
  --www-google-com-roboto-medium-15-63-line-height: 24px;
  --www-google-com-roboto-medium-15-75-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-medium-15-75-font-size: 15.75px;
  --www-google-com-roboto-medium-15-75-font-style: normal;
  --www-google-com-roboto-medium-15-75-font-weight: 500;
  --www-google-com-roboto-medium-15-75-letter-spacing: 0.10000000149011612px;
  --www-google-com-roboto-medium-15-75-line-height: 24px;
  --www-google-com-roboto-medium-15-88-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-medium-15-88-font-size: 15.875px;
  --www-google-com-roboto-medium-15-88-font-style: normal;
  --www-google-com-roboto-medium-15-88-font-weight: 500;
  --www-google-com-roboto-medium-15-88-letter-spacing: 0.10000000149011612px;
  --www-google-com-roboto-medium-15-88-line-height: 24px;
  --www-google-com-roboto-medium-15-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-medium-15-font-size: 15px;
  --www-google-com-roboto-medium-15-font-style: normal;
  --www-google-com-roboto-medium-15-font-weight: 500;
  --www-google-com-roboto-medium-15-letter-spacing: 0.10000000149011612px;
  --www-google-com-roboto-medium-15-line-height: 24px;
  --www-google-com-roboto-medium-16-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-medium-16-font-size: 16px;
  --www-google-com-roboto-medium-16-font-style: normal;
  --www-google-com-roboto-medium-16-font-weight: 500;
  --www-google-com-roboto-medium-16-letter-spacing: 0.10000000149011612px;
  --www-google-com-roboto-medium-16-line-height: 24px;
  --www-google-com-roboto-regular-12-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-regular-12-font-size: 12px;
  --www-google-com-roboto-regular-12-font-style: normal;
  --www-google-com-roboto-regular-12-font-weight: 400;
  --www-google-com-roboto-regular-12-letter-spacing: 0.30000001192092896px;
  --www-google-com-roboto-regular-12-line-height: 16px;
  --www-google-com-roboto-regular-13-78-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-regular-13-78-font-size: 13.78125px;
  --www-google-com-roboto-regular-13-78-font-style: normal;
  --www-google-com-roboto-regular-13-78-font-weight: 400;
  --www-google-com-roboto-regular-13-78-letter-spacing: 0.25px;
  --www-google-com-roboto-regular-13-78-line-height: 20px;
  --www-google-com-roboto-regular-13-89-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-regular-13-89-font-size: 13.890625px;
  --www-google-com-roboto-regular-13-89-font-style: normal;
  --www-google-com-roboto-regular-13-89-font-weight: 400;
  --www-google-com-roboto-regular-13-89-letter-spacing: 0.25px;
  --www-google-com-roboto-regular-13-89-line-height: 20px;
  --www-google-com-roboto-regular-14-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-regular-14-font-size: 14px;
  --www-google-com-roboto-regular-14-font-style: normal;
  --www-google-com-roboto-regular-14-font-weight: 400;
  --www-google-com-roboto-regular-14-letter-spacing: 0.25px;
  --www-google-com-roboto-regular-14-line-height: 20px;
  --www-google-com-roboto-regular-15-88-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-regular-15-88-font-size: 15.875px;
  --www-google-com-roboto-regular-15-88-font-style: normal;
  --www-google-com-roboto-regular-15-88-font-weight: 400;
  --www-google-com-roboto-regular-15-88-letter-spacing: 0px;
  --www-google-com-roboto-regular-15-88-line-height: normal;
  --www-google-com-roboto-regular-17-58-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-regular-17-58-font-size: 17.578125px;
  --www-google-com-roboto-regular-17-58-font-style: normal;
  --www-google-com-roboto-regular-17-58-font-weight: 400;
  --www-google-com-roboto-regular-17-58-letter-spacing: 0px;
  --www-google-com-roboto-regular-17-58-line-height: 24px;
  --www-google-com-roboto-regular-18-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-regular-18-font-size: 18px;
  --www-google-com-roboto-regular-18-font-style: normal;
  --www-google-com-roboto-regular-18-font-weight: 400;
  --www-google-com-roboto-regular-18-letter-spacing: 0px;
  --www-google-com-roboto-regular-18-line-height: 24px;
  --www-google-com-roboto-regular-24-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-regular-24-font-size: 24px;
  --www-google-com-roboto-regular-24-font-style: normal;
  --www-google-com-roboto-regular-24-font-weight: 400;
  --www-google-com-roboto-regular-24-letter-spacing: 0px;
  --www-google-com-roboto-regular-24-line-height: 32px;
  --wwwgooglecomathens-gray: rgba(232, 234, 237, 1);
  --wwwgooglecombamboo: rgba(219, 89, 1, 1);
  --wwwgooglecomblack: rgba(0, 0, 0, 1);
  --wwwgooglecomblack-87: rgba(0, 0, 0, 0.87);
  --wwwgooglecomcalifornia: rgba(242, 153, 0, 1);
  --wwwgooglecomcape-cod: rgba(60, 64, 67, 1);
  --wwwgooglecomchateau-green: rgba(52, 168, 83, 1);
  --wwwgooglecomcinnabar: rgba(234, 67, 53, 1);
  --wwwgooglecomcornflower-blue: rgba(66, 133, 244, 1);
  --wwwgooglecomcrimson: rgba(237, 22, 30, 1);
  --wwwgooglecomdenim: rgba(25, 103, 210, 1);
  --wwwgooglecomdove-gray: rgba(102, 102, 102, 1);
  --wwwgooglecomindochine: rgba(194, 108, 3, 1);
  --wwwgooglecomiron: rgba(218, 220, 224, 1);
  --wwwgooglecomjewel: rgba(24, 128, 56, 1);
  --wwwgooglecommantis: rgba(130, 204, 82, 1);
  --wwwgooglecomnero: rgba(255, 255, 255, 1);
  --wwwgooglecomnero-02: rgba(255, 255, 255, 0);
  --wwwgooglecomoslo-gray: rgba(128, 134, 139, 1);
  --wwwgooglecompanache: rgba(230, 244, 234, 1);
  --wwwgooglecomporcelain: rgba(241, 243, 244, 1);
  --wwwgooglecomprovincial-pink: rgba(252, 232, 230, 1);
  --wwwgooglecompunch: rgba(217, 48, 37, 1);
  --wwwgooglecomroyal-blue: rgba(26, 115, 232, 1);
  --wwwgooglecomroyal-blue-10: rgba(28, 116, 233, 0.1);
  --wwwgooglecomselective-yellow: rgba(251, 188, 5, 1);
  --wwwgooglecomshark: rgba(32, 33, 36, 1);
  --wwwgooglecomshuttle-gray: rgba(95, 99, 104, 1);
  --wwwgooglecomsilver-sand: rgba(189, 193, 198, 1);
  --wwwgooglecomtamarillo: rgba(165, 14, 14, 1);
  --wwwgooglecomthunderbird: rgba(197, 34, 31, 1);
  --wwwgooglecomzumthor: rgba(232, 240, 254, 1);
}

/*

To enable a theme in your HTML, simply add one of the following data attributes to an HTML element, like so:

<body data-pick-theme-mode="blue">
    <!-- the rest of your content -->
</body>

You can apply the theme on any DOM node, not just the `body`

*/

[data-pick-theme-mode="blue"] {
  --pick-theme-primary-base: var(--foundations-blue-base);
  --pick-theme-primary-dark: var(--foundations-blue-dark);
  --pick-theme-primary-darker: var(--foundations-blue-darker);
  --pick-theme-primary-light: var(--foundations-blue-light);
  --pick-theme-primary-lighter: var(--foundations-blue-lighter);
}

[data-pick-theme-mode="purple"] {
  --pick-theme-primary-base: var(--foundations-purple-base);
  --pick-theme-primary-dark: var(--foundations-purple-dark);
  --pick-theme-primary-darker: var(--foundations-purple-darker);
  --pick-theme-primary-light: var(--foundations-purple-light);
  --pick-theme-primary-lighter: var(--foundations-purple-lighter);
}
