.your-points-mobile {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.your-points-mobile .div-2 {
  background-color: #ffffff;
  overflow: hidden;
  position: relative;
}

.your-points-mobile .frame-6 {
  position: absolute;
}

.your-points-mobile .frame-7 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  height: 33px;
  position: relative;
}

.your-points-mobile .logo {
  height: 24px;
  position: relative;
  width: 116px;
}

.your-points-mobile .vector {
  height: 19px;
  left: 36px;
  position: absolute;
  top: 3px;
  width: 78px;
}

.your-points-mobile .logomark {
  height: 17px;
  left: 0;
  position: absolute;
  top: 2px;
  width: 25px;
}

.your-points-mobile .frame-8 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 16px;
  margin-bottom: -26px;
  position: relative;
}

.your-points-mobile .text-wrapper-4 {
  color: #5e6773;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.16px;
  line-height: 25px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: 100%;
}

.your-points-mobile .group {
  height: 727px;
  left: 0;
  position: absolute;
  top: 0;
  width: 6907px;
}

.your-points-mobile .overlap-group {
  background-image: url(../../../static/img/crosschain-lines-svg.svg);
  background-size: 100% 100%;
  height: 611px;
  left: 2896px;
  position: relative;
  top: 116px;
  width: 1440px;
}

.your-points-mobile .div-cda {
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgb(255, 255, 255) 0%,
    rgb(255, 255, 255) 29.69%,
    rgba(255, 255, 255, 0.64) 62.5%,
    rgba(255, 255, 255, 0) 100%
  );
  height: 479px;
  left: 274px;
  position: relative;
  top: 13px;
  width: 859px;
}

.your-points-mobile .frame-9 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 40px;
  left: 3379px;
  position: absolute;
  top: 284px;
}

.your-points-mobile .frame-10 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.your-points-mobile .div-3 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.your-points-mobile .text-wrapper-5 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    180deg,
    rgb(0, 102.28, 219.43) 0%,
    rgba(13.34, 110.51, 221.81, 0.96) 37.5%,
    rgba(190.63, 219.93, 253.49, 0.38) 100%
  );
  background-clip: text;
  color: transparent;
  font-family: "Aeonik-Medium", Helvetica;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.2px;
  line-height: 38px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  text-fill-color: transparent;
  white-space: nowrap;
  width: 100%;
}

.your-points-mobile .text-wrapper-6 {
  color: #000000;
  font-family: "Aeonik-Bold", Helvetica;
  font-size: 64px;
  font-weight: 700;
  letter-spacing: -0.64px;
  line-height: 80px;
  position: relative;
  width: 350px;
}

.your-points-mobile .text-wrapper-7 {
  color: #5e6773;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 49px;
  letter-spacing: -0.16px;
  line-height: 25px;
  position: relative;
  text-align: center;
  width: 474px;
}

.your-points-mobile .button {
  all: unset;
  align-items: center;
  background-color: #121315;
  border-radius: 26px;
  box-shadow: var(--small-elements);
  box-sizing: border-box;
  display: inline-flex;
  height: 40px;
  justify-content: flex-end;
  overflow: hidden;
  padding: 10px 16px;
  position: relative;
  cursor: pointer;
}

.your-points-mobile .text-wrapper-8 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.14px;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;
}

.your-points-mobile .header {
  align-items: center;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  left: 2896px;
  padding: 16px 32px;
  position: absolute;
  top: 116px;
  width: 1440px;
}

.your-points-mobile .frame-11 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 12px;
  justify-content: center;
  position: relative;
}

.your-points-mobile .button-2 {
  all: unset;
  align-items: center;
  background-color: #ffffff;
  border-radius: 26px;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  height: 40px;
  justify-content: flex-end;
  overflow: hidden;
  padding: 10px 16px;
  position: relative;
  cursor: pointer;
}

.your-points-mobile .text-wrapper-9 {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.14px;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: 100%;
}

.your-points-mobile .right {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  position: relative;
  width: 116px;
}

.your-points-mobile .button-3 {
  all: unset;
  align-items: center;
  background-color: #121315;
  border-radius: 26px;
  box-shadow: var(--small-elements);
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  height: 40px;
  justify-content: flex-end;
  overflow: hidden;
  padding: 10px 16px;
  position: relative;
}

.your-points-mobile .frame-12 {
  align-items: flex-end;
  display: inline-flex;
  flex-direction: column;
  gap: 20px;
  left: 2996px;
  position: absolute;
  top: 616px;
}

.your-points-mobile .overlap-group-wrapper {
  background-color: var(--matchaxyzcross-chain-swaps1024x1820defaultwhite-lilac);
  border: 1px solid;
  border-color: #f1f2f4;
  border-radius: 36px 36px 12px 12px;
  height: 350px;
  overflow: hidden;
  position: relative;
  width: 1240px;
}

.your-points-mobile .heading-margin-wrapper {
  background-image: url(../../../static/img/signup-lines-svg.svg);
  background-size: 100% 100%;
  height: 212px;
  position: relative;
  top: 42px;
}

.your-points-mobile .heading-margin {
  align-items: center;
  background: radial-gradient(50% 50% at 50% 50%, rgb(252, 252, 253) 0%, rgba(252, 252, 253, 0) 100%);
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
  left: 329px;
  position: relative;
  top: 12px;
}

.your-points-mobile .text-wrapper-10 {
  color: var(--matchaxyzcross-chain-swaps1024x1820defaultshark);
  font-family: "Aeonik-Bold", Helvetica;
  font-size: 35.7px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 38px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: 100%;
}

.your-points-mobile .by-inputting-your {
  color: var(--matchaxyzcross-chain-swaps1024x1820defaultshuttle-gray);
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 25px;
  position: relative;
  text-align: center;
  width: 100%;
}

.your-points-mobile .input-2 {
  align-items: flex-start;
  background-color: var(--matchaxyzcross-chain-swaps1024x1820defaultbright-gray-5);
  border: 1px solid;
  border-color: var(--matchaxyzcross-chain-swaps1024x1820defaultiron);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  height: 52px;
  max-width: 434px;
  padding: 16.25px 57px 15.75px 21px;
  position: relative;
  width: 430px;
}

.your-points-mobile .input-2 .input-field {
  flex-grow: 1; /* Allows the input to fill the space */
  border: none; /* Removes the default input border */
  outline: none; /* Removes the focus outline to match the design */
  color: var(--matchaxyzcross-chain-swaps1024x1820defaultmanatee);
  font-family: var(--matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-font-family);
  font-size: var(--matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-font-size);
  font-style: var(--matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-font-style);
  font-weight: var(--matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-font-weight);
  letter-spacing: var(--matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-letter-spacing);
  line-height: var(--matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-line-height);
  position: relative;
  white-space: nowrap;
  width: 100%;
  background: none;
}

.your-points-mobile .input-2 .button-submit-email {
  cursor: pointer;
  position: absolute;
  right: 10px; /* Adjust as necessary */
  top: 50%;
  transform: translateY(-50%);
}

.your-points-mobile .div-placeholder {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 1px 246px 0px 0px;
  position: relative;
  width: 100%;
}

.your-points-mobile .text-wrapper-11 {
  color: var(--matchaxyzcross-chain-swaps1024x1820defaultmanatee);
  font-family: var(--matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-font-family);
  font-size: var(--matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-font-size);
  font-style: var(--matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-font-style);
  font-weight: var(--matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-font-weight);
  letter-spacing: var(--matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-letter-spacing);
  line-height: var(--matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: 100%;
}

.your-points-mobile .button-submit-email {
  height: 40px;
  left: 385px;
  position: absolute;
  top: 6px;
  width: 40px;
}

.your-points-mobile .div-kee {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 20px;
  justify-content: center;
  max-width: 1240px;
  position: relative;
  width: 1240px;
}

.your-points-mobile .div-4 {
  align-items: center;
  align-self: stretch;
  background-color: var(--matchaxyzabout-matcha-dex-aggregator1024x1820defaultwhite-lilac);
  border: 1px solid;
  border-color: #f1f2f4;
  border-radius: 12px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  padding: 29px 59.22px 45px 59.21px;
  position: relative;
}

.your-points-mobile .div-yevd {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  height: 38px;
  padding: 0px 0px 7px;
  position: relative;
}

.your-points-mobile .div-keee {
  align-items: center;
  align-self: stretch;
  background-color: var(--matchaxyzabout-matcha-dex-aggregator1024x1820defaultnero);
  border: 2px solid;
  border-color: var(--matchaxyzabout-matcha-dex-aggregator1024x1820defaultiron);
  border-radius: 72px;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: 6px 15px 6px 8px;
  position: relative;
  width: 100%;
  z-index: 1;
}

.your-points-mobile .image {
  background-image: url(../../../static/img/image.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 18px;
  position: relative;
  width: 18px;
}

.your-points-mobile .text-wrapper-12 {
  color: var(--matchaxyzabout-matcha-dex-aggregator1024x1820defaultblack);
  font-family: "Inter", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 12px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: 100%;
}

.your-points-mobile .div-keeh {
  align-self: stretch;
  height: 1px;
  position: relative;
  width: 100%;
  z-index: 0;
}

.your-points-mobile .overlap-group-2 {
  height: 32px;
  left: 12px;
  position: relative;
  top: -24px;
  width: 98px;
}

.your-points-mobile .after {
  background-color: var(--matchaxyzabout-matcha-dex-aggregator1024x1820defaultnero);
  border: 2px solid;
  border-color: var(--matchaxyzabout-matcha-dex-aggregator1024x1820defaultiron);
  border-radius: 8px;
  height: 28px;
  left: 12px;
  position: absolute;
  top: 4px;
  width: 75px;
}

.your-points-mobile .before {
  background-color: var(--matchaxyzabout-matcha-dex-aggregator1024x1820defaultnero);
  border: 2px solid;
  border-color: var(--matchaxyzabout-matcha-dex-aggregator1024x1820defaultiron);
  border-radius: 8px;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 98px;
}

.your-points-mobile .text-wrapper-13 {
  color: var(--matchaxyzabout-matcha-dex-aggregator1024x1820defaultblack);
  font-family: "Aeonik-Bold", Helvetica;
  font-size: 63.6px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 78px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: 100%;
}

.your-points-mobile .text-wrapper-14 {
  color: var(--matchaxyzabout-matcha-dex-aggregator1024x1820defaultmanatee);
  font-family: "Inter", Helvetica;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: 100%;
}

.your-points-mobile .div-5 {
  align-items: center;
  align-self: stretch;
  background-color: var(--matchaxyzabout-matcha-dex-aggregator1024x1820defaultwhite-lilac);
  border: 1px solid;
  border-color: #f1f2f4;
  border-radius: 12px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  padding: 29px 26.9px 45px 27px;
  position: relative;
}

.your-points-mobile .div-keer {
  align-self: stretch;
  height: 111px;
  margin-top: -41px;
  position: relative;
  width: 100%;
}

.your-points-mobile .text-wrapper-15 {
  color: var(--matchaxyzabout-matcha-dex-aggregator1024x1820defaultblack);
  font-family: "Aeonik-Bold", Helvetica;
  font-size: 64px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 78px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: 100%;
}

.your-points-mobile .text-wrapper-16 {
  color: var(--matchaxyzabout-matcha-dex-aggregator1024x1820defaultmanatee);
  font-family: "Inter", Helvetica;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  text-align: center;
  width: 128px;
}

.your-points-mobile .div-6 {
  align-items: center;
  align-self: stretch;
  background-color: var(--matchaxyzabout-matcha-dex-aggregator1024x1820defaultwhite-lilac);
  border: 1px solid;
  border-color: #f1f2f4;
  border-radius: 12px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  padding: 29px 27px 45px;
  position: relative;
}

.your-points-mobile .text-wrapper-17 {
  color: var(--matchaxyzabout-matcha-dex-aggregator1024x1820defaultblack);
  font-family: "Aeonik-Bold", Helvetica;
  font-size: 64px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 78px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: 100%;
}

.your-points-mobile .overlap-wrapper {
  align-self: stretch;
  background-color: var(--matchaxyzabout-matcha-dex-aggregator1024x1820defaultwhite-lilac);
  border: 1px solid;
  border-color: #f1f2f4;
  border-radius: 12px;
  flex: 1;
  flex-grow: 1;
  position: relative;
}

.your-points-mobile .overlap {
  background-image: url(../../../static/img/trade-volume-icon-svg-fill.svg);
  background-size: 100% 100%;
  height: 214px;
  width: 295px;
}

.your-points-mobile .frame-13 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  left: 86px;
  position: relative;
  top: 60px;
}

.your-points-mobile .section-wrapper {
  background-color: #fcfcfd;
  border: 1px solid;
  border-color: #f1f2f4;
  border-radius: 12px 12px 36px 36px;
  height: 476.64px;
  overflow: hidden;
  position: relative;
  width: 1240px;
}

.your-points-mobile .overlap-2 {
  background-image: url(../../../static/img/wave-lines-svg-fill.svg);
  background-size: 100% 100%;
  height: 476px;
  position: relative;
}

.your-points-mobile .list {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  left: 61px;
  position: absolute;
  top: 173px;
  width: 1118px;
}

.your-points-mobile .item {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0px 0px 52px;
  position: relative;
  width: 324px;
}

.your-points-mobile .SVG {
  height: 126px;
  position: relative;
  width: 126px;
}

.your-points-mobile .text-wrapper-18 {
  align-self: stretch;
  color: #5e6773;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  text-align: center;
}

.your-points-mobile .item-2 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 324px;
}

.your-points-mobile .item-3 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0px 0px 53px;
  position: relative;
  width: 324px;
}

.your-points-mobile .SVG-2 {
  height: 125px;
  position: relative;
  width: 126px;
}

.your-points-mobile .heading {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 12px;
  left: 446px;
  position: absolute;
  top: 42px;
}

.your-points-mobile .airdrop-how-to-earn {
  align-self: stretch;
  color: transparent;
  font-family: "Aeonik-Bold", Helvetica;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 40px;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.your-points-mobile .span {
  color: #a0a0ab;
}

.your-points-mobile .text-wrapper-19 {
  color: #000000;
}

.your-points-mobile .text-wrapper-20 {
  color: #5e6773;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 25px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: 100%;
}

.your-points-mobile .overlap-3 {
  position: absolute;
}

.your-points-mobile .overlap-group-3 {
  height: 612px;
  left: 3170px;
  position: relative;
  top: 115px;
  width: 859px;
}

.your-points-mobile .crosschain-lines-svg {
  height: 612px;
  left: 186px;
  position: absolute;
  top: 0;
  width: 393px;
}

.your-points-mobile .div-7 {
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgb(255, 255, 255) 0%,
    rgb(255, 255, 255) 29.69%,
    rgba(255, 255, 255, 0.64) 62.5%,
    rgba(255, 255, 255, 0) 100%
  );
  height: 479px;
  left: 0;
  position: absolute;
  top: 14px;
  width: 859px;
}

.your-points-mobile .header-2 {
  align-items: center;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  left: 3356px;
  padding: 16px 24px;
  position: absolute;
  top: 115px;
  width: 393px;
}

.your-points-mobile .logo-2 {
  height: 20px;
  position: relative;
  width: 96.67px;
}

.your-points-mobile .vector-2 {
  height: 16px;
  left: 30px;
  position: absolute;
  top: 2px;
  width: 65px;
}

.your-points-mobile .logomark-2 {
  height: 14px;
  left: 0;
  position: absolute;
  top: 2px;
  width: 20px;
}

.your-points-mobile .right-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}

.your-points-mobile .buttons {
  align-items: center;
  background-color: var(--neutral-900);
  border-radius: 24px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: 8px 16px;
  position: relative;
}

.your-points-mobile .label {
  color: var(--neutral-0);
  font-family: var(--typography-action-font-family);
  font-size: var(--typography-action-font-size);
  font-style: var(--typography-action-font-style);
  font-weight: var(--typography-action-font-weight);
  letter-spacing: var(--typography-action-letter-spacing);
  line-height: var(--typography-action-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: 100%;
}

.your-points-mobile .button-4 {
  flex: 0 0 auto;
  margin-bottom: -3px;
  margin-right: -2px;
  margin-top: -1px;
  position: relative;
}

.your-points-mobile .frame-14 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 40px;
  left: 3380px;
  position: absolute;
  top: 263px;
  width: 345px;
}

.your-points-mobile .text-wrapper-21 {
  align-self: stretch;
  color: #5e6773;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.16px;
  line-height: 25px;
  position: relative;
  text-align: center;
}

.your-points-mobile .frame-15 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
  left: 3380px;
  position: absolute;
  top: 608px;
}

.your-points-mobile .frame-16 {
  background-color: var(--matchaxyzcross-chain-swaps1024x1820defaultwhite-lilac);
  border: 1px solid;
  border-color: #f1f2f4;
  border-radius: 36px 36px 12px 12px;
  height: 375px;
  overflow: hidden;
  position: relative;
  width: 340px;
}

.your-points-mobile .overlap-group-4 {
  height: 226px;
  position: relative;
  top: 35px;
}

.your-points-mobile .signup-lines-svg {
  height: 212px;
  left: 0;
  position: absolute;
  top: 7px;
  width: 340px;
}

.your-points-mobile .heading-margin-2 {
  align-items: center;
  background: radial-gradient(50% 50% at 50% 50%, rgb(252, 252, 253) 0%, rgba(252, 252, 253, 0) 100%);
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 226px;
  left: 19px;
  position: absolute;
  top: 0;
  width: 301px;
}

.your-points-mobile .text-wrapper-22 {
  align-self: stretch;
  color: var(--matchaxyzcross-chain-swaps1024x1820defaultshark);
  font-family: "Aeonik-Bold", Helvetica;
  font-size: 35.7px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 38px;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.your-points-mobile .text-wrapper-23 {
  align-self: stretch;
  color: var(--matchaxyzcross-chain-swaps1024x1820defaultshuttle-gray);
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 25px;
  position: relative;
  text-align: center;
}

.your-points-mobile .input-3 {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--matchaxyzcross-chain-swaps1024x1820defaultbright-gray-5);
  border: 1px solid;
  border-color: var(--matchaxyzcross-chain-swaps1024x1820defaultiron);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  height: 52px;
  max-width: 434px;
  padding: 16.25px 57px 15.75px 21px;
  position: relative;
  width: 100%;
}
.your-points-mobile .input-3 .input-field {
  flex-grow: 1; /* Allows the input to fill the space */
  border: none; /* Removes the default input border */
  outline: none; /* Removes the focus outline to match the design */
  color: var(--matchaxyzcross-chain-swaps1024x1820defaultmanatee);
  font-family: var(--matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-font-family);
  font-size: var(--matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-font-size);
  font-style: var(--matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-font-style);
  font-weight: var(--matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-font-weight);
  letter-spacing: var(--matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-letter-spacing);
  line-height: var(--matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-line-height);
  position: relative;
  white-space: nowrap;
  width: 100%;
  background: none;
}

.your-points-mobile .text-wrapper-24 {
  color: var(--matchaxyzcross-chain-swaps1024x1820defaultmanatee);
  font-family: var(--matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-font-family);
  font-size: var(--matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-font-size);
  font-style: var(--matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-font-style);
  font-weight: var(--matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-font-weight);
  letter-spacing: var(--matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-letter-spacing);
  line-height: var(--matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-line-height);
  margin-right: -86px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: 100%;
}

.your-points-mobile .button-submit-email-2 {
  height: 40px;
  left: 256px;
  position: absolute;
  top: 6px;
  width: 40px;
}

.your-points-mobile .div-8 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  max-width: 1240px;
  position: relative;
  width: 340px;
}

.your-points-mobile .div-9 {
  align-items: center;
  align-self: stretch;
  background-color: var(--matchaxyzabout-matcha-dex-aggregator1024x1820defaultwhite-lilac);
  border: 1px solid;
  border-color: #f1f2f4;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  height: 214px;
  padding: 29px 59.22px 45px 59.21px;
  position: relative;
  width: 100%;
}

.your-points-mobile .div-10 {
  align-items: center;
  align-self: stretch;
  background-color: var(--matchaxyzabout-matcha-dex-aggregator1024x1820defaultwhite-lilac);
  border: 1px solid;
  border-color: #f1f2f4;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  height: 214px;
  padding: 29px 26.9px 45px 27px;
  position: relative;
  width: 100%;
}

.your-points-mobile .div-11 {
  align-items: center;
  align-self: stretch;
  background-color: var(--matchaxyzabout-matcha-dex-aggregator1024x1820defaultwhite-lilac);
  border: 1px solid;
  border-color: #f1f2f4;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  height: 214px;
  padding: 29px 27px 45px;
  position: relative;
  width: 100%;
}

.your-points-mobile .div-12 {
  align-self: stretch;
  background-color: var(--matchaxyzabout-matcha-dex-aggregator1024x1820defaultwhite-lilac);
  border: 1px solid;
  border-color: #f1f2f4;
  border-radius: 12px;
  height: 214px;
  position: relative;
  width: 100%;
}

.your-points-mobile .overlap-4 {
  background-image: url(../../../static/img/trade-volume-icon-svg-fill-1.svg);
  background-size: 100% 100%;
  height: 214px;
  width: 340px;
}

.your-points-mobile .section-wrapper-2 {
  background-color: #fcfcfd;
  border: 1px solid;
  border-color: #f1f2f4;
  border-radius: 12px 12px 36px 36px;
  height: 936px;
  overflow: hidden;
  position: relative;
  width: 340px;
}

.your-points-mobile .overlap-5 {
  height: 670px;
  left: 0;
  position: absolute;
  top: 257px;
  width: 340px;
}

.your-points-mobile .wave-lines-svg-fill {
  height: 476px;
  left: 0;
  position: absolute;
  top: 162px;
  width: 340px;
}

.your-points-mobile .list-2 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
  left: 8px;
  position: absolute;
  top: 0;
}

.your-points-mobile .item-4 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;
}

.your-points-mobile .text-wrapper-25 {
  color: #5e6773;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  text-align: center;
  width: 260px;
}

.your-points-mobile .item-5 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 324px;
}

.your-points-mobile .item-6 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  padding: 0px 0px 53px;
  position: relative;
  width: 100%;
}

.your-points-mobile .heading-2 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 12px;
  left: 30px;
  position: absolute;
  top: 27px;
  width: 279px;
}

.your-points-mobile .text-wrapper-26 {
  align-self: stretch;
  color: #5e6773;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 25px;
  position: relative;
  text-align: center;
}

.your-points-mobile .frame-17 {
  align-items: center;
  display: flex;
  height: 33px;
  justify-content: space-between;
  position: relative;
  width: 1240px;
}

.your-points-mobile .frame-18 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 16px;
  position: relative;
}

.your-points-mobile .user-code-container {
  position: relative;
}

.your-points-mobile .copy-button {
  height: 20px;
  width: 20px;
  all: unset;
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  padding: 5px 8px;
  border-radius: 5px;
}

.your-points-mobile .input-container {
  display: flex;
  justify-content: center;
  gap: 20px;
}
