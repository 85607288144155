@keyframes pulse {
    0% {
        background-color: #f5f5f6;
    }

    50% {
        background-color: #e4e4e7;
    }

    100% {
        background-color: #f5f5f6;
    }
}

.loading-spinner {
    display: inline-block;
    width: 100px;
    height: 20px;
    border-radius: 10px;
    animation: pulse 1.5s infinite ease-in-out;
}