.logo {
  height: 24px;
  position: relative;
  width: 116px;
}

.logo .vector {
  height: 19px;
  left: 36px;
  position: absolute;
  top: 3px;
  width: 78px;
}
