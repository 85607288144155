:root {
  --avatar-shadow-head-blue: 0px 4px 4px 0px #375dfb3d;
  --avatar-shadow-head-gray: 0px 4px 4px 0px #8a8f9329;
  --avatar-shadow-head-purple: 0px 4px 4px 0px #6e3ff33d;
  --avatar-shadow-head-red: 0px 4px 4px 0px #e935353d;
  --avatar-shadow-head-teal: 0px 4px 4px 0px #2cbdf23d;
  --avatar-shadow-head-yellow: 0px 4px 4px 0px #ffb41f3d;
  --avatar-shadow-whole-blue: ;
  --avatar-shadow-whole-gray: ;
  --avatar-shadow-whole-purple: ;
  --avatar-shadow-whole-red: ;
  --avatar-shadow-whole-teal: ;
  --avatar-shadow-whole-yellow: ;
  --bluedarker: #005fcc;
  --bluenormal: #07f;
  --button-shadow-fancy-blue: 0px 0px 0px 1px #375dfb, 0px 1px 2px 0px #253ea77a;
  --button-shadow-fancy-important: 0px 0px 0px 1px #242628, 0px 1px 2px 0px #1b1c1d7a;
  --button-shadow-fancy-purple: 0px 0px 0px 1px #6e3ff3, 0px 1px 2px 0px #5a36bf7a;
  --button-shadow-fancy-red: 0px 0px 0px 1px #e93535, 0px 1px 2px 0px #af1d1d7a;
  --button-shadow-focus-error: 0px 0px 0px 4px #ffeceb, 0px 0px 0px 2px #fff;
  --button-shadow-focus-important: 0px 0px 0px 4px #e4e5e7, 0px 0px 0px 2px #fff;
  --button-shadow-focus-primary: 0px 0px 0px 4px #ebf1ff, 0px 0px 0px 2px #fff;
  --button-shadow-stroke-error: 0px 1px 2px 0px #e9353514;
  --button-shadow-stroke-important: 0px 1px 2px 0px #5258660f;
  --button-shadow-stroke-primary: 0px 1px 2px 0px #375dfb14;
  --docs-label-font-family: "Inter", Helvetica;
  --docs-label-font-size: 18px;
  --docs-label-font-style: normal;
  --docs-label-font-weight: 500;
  --docs-label-letter-spacing: -.27px;
  --docs-label-line-height: 32px;
  --docs-paragraph-font-family: "Inter", Helvetica;
  --docs-paragraph-font-size: 18px;
  --docs-paragraph-font-style: normal;
  --docs-paragraph-font-weight: 400;
  --docs-paragraph-letter-spacing: -.27px;
  --docs-paragraph-line-height: 32px;
  --fancy-buttonblue-default: var(--foundations-blue-base);
  --fancy-buttonblue-hover: var(--foundations-blue-base);
  --fancy-buttonblue-pressed: var(--foundations-blue-base);
  --fancy-buttonneutral-default: var(--tokens-bg-surface-700);
  --fancy-buttonneutral-hover: var(--tokens-bg-surface-700);
  --fancy-buttonneutral-pressed: var(--tokens-bg-surface-700);
  --fancy-buttonpurple-default: var(--foundations-purple-base);
  --fancy-buttonpurple-hover: var(--foundations-purple-base);
  --fancy-buttonpurple-pressed: var(--foundations-purple-base);
  --fancy-buttonred-default: var(--foundations-red-base);
  --fancy-buttonred-hover: var(--foundations-red-base);
  --fancy-buttonred-pressed: var(--foundations-red-base);
  --foundations-blue-base: #375dfb;
  --foundations-blue-dark: #253ea7;
  --foundations-blue-darker: #162664;
  --foundations-blue-light: #c2d6ff;
  --foundations-blue-lighter: #ebf1ff;
  --foundations-green-base: #38c793;
  --foundations-green-dark: #2d9f75;
  --foundations-green-darker: #176448;
  --foundations-green-light: #cbf5e5;
  --foundations-green-lighter: #effaf6;
  --foundations-neutral-0: #fff;
  --foundations-neutral-100: #f6f8fa;
  --foundations-neutral-200: #e2e4e9;
  --foundations-neutral-300: #cdd0d5;
  --foundations-neutral-400: #868c98;
  --foundations-neutral-500: #525866;
  --foundations-neutral-600: #31353f;
  --foundations-neutral-700: #20232d;
  --foundations-neutral-800: #161922;
  --foundations-neutral-900: #0a0d14;
  --foundations-orange-base: #f17b2c;
  --foundations-orange-dark: #c2540a;
  --foundations-orange-darker: #6e330c;
  --foundations-orange-light: #ffdac2;
  --foundations-orange-lighter: #fef3eb;
  --foundations-pink-base: #e255f2;
  --foundations-pink-dark: #9c23a9;
  --foundations-pink-darker: #620f6c;
  --foundations-pink-light: #f9c2ff;
  --foundations-pink-lighter: #fdebff;
  --foundations-purple-base: #6e3ff3;
  --foundations-purple-dark: #5a36bf;
  --foundations-purple-darker: #2b1664;
  --foundations-purple-light: #cac2ff;
  --foundations-purple-lighter: #eeebff;
  --foundations-red-base: #df1c41;
  --foundations-red-dark: #af1d38;
  --foundations-red-darker: #710e21;
  --foundations-red-light: #f8c9d2;
  --foundations-red-lighter: #fdedf0;
  --foundations-teal-base: #35b9e9;
  --foundations-teal-dark: #1f87ad;
  --foundations-teal-darker: #164564;
  --foundations-teal-light: #c2efff;
  --foundations-teal-lighter: #ebfaff;
  --foundations-yellow-base: #f2ae40;
  --foundations-yellow-dark: #b47818;
  --foundations-yellow-darker: #693d11;
  --foundations-yellow-light: #fbdfb1;
  --foundations-yellow-lighter: #fef7ec;
  --label-large-font-family: "Inter", Helvetica;
  --label-large-font-size: 18px;
  --label-large-font-style: normal;
  --label-large-font-weight: 500;
  --label-large-letter-spacing: -.27px;
  --label-large-line-height: 24px;
  --label-medium-font-family: "Inter", Helvetica;
  --label-medium-font-size: 16px;
  --label-medium-font-style: normal;
  --label-medium-font-weight: 500;
  --label-medium-letter-spacing: -.176px;
  --label-medium-line-height: 24px;
  --label-small-font-family: "Inter", Helvetica;
  --label-small-font-size: 14px;
  --label-small-font-style: normal;
  --label-small-font-weight: 500;
  --label-small-letter-spacing: -.084px;
  --label-small-line-height: 20px;
  --label-x-large-font-family: "Inter", Helvetica;
  --label-x-large-font-size: 24px;
  --label-x-large-font-style: normal;
  --label-x-large-font-weight: 500;
  --label-x-large-letter-spacing: -.36px;
  --label-x-large-line-height: 32px;
  --label-x-small-font-family: "Inter", Helvetica;
  --label-x-small-font-size: 12px;
  --label-x-small-font-style: normal;
  --label-x-small-font-weight: 500;
  --label-x-small-letter-spacing: 0px;
  --label-x-small-line-height: 16px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-19-84-font-family: "Gilroy", Helvetica;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-19-84-font-size: 19.8438px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-19-84-font-style: normal;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-19-84-font-weight: 800;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-19-84-letter-spacing: 0px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-19-84-line-height: 56px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-35-58-font-family: "Gilroy", Helvetica;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-35-58-font-size: 35.5781px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-35-58-font-style: normal;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-35-58-font-weight: 800;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-35-58-letter-spacing: 0px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-35-58-line-height: 39.6px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-35-72-font-family: "Gilroy", Helvetica;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-35-72-font-size: 35.7188px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-35-72-font-style: normal;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-35-72-font-weight: 800;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-35-72-letter-spacing: 0px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-35-72-line-height: 38px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-35-86-font-family: "Gilroy", Helvetica;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-35-86-font-size: 35.8594px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-35-86-font-style: normal;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-35-86-font-weight: 800;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-35-86-letter-spacing: 0px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-35-86-line-height: 39.6px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-50-font-family: "Gilroy", Helvetica;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-50-font-size: 50px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-50-font-style: normal;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-50-font-weight: 800;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-50-letter-spacing: 0px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-50-line-height: 78px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-63-25-font-family: "Gilroy", Helvetica;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-63-25-font-size: 63.25px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-63-25-font-style: normal;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-63-25-font-weight: 800;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-63-25-letter-spacing: 0px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-63-25-line-height: 78px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-63-63-font-family: "Gilroy", Helvetica;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-63-63-font-size: 63.625px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-63-63-font-style: normal;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-63-63-font-weight: 800;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-63-63-letter-spacing: 0px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-63-63-line-height: 78px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-63-75-font-family: "Gilroy", Helvetica;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-63-75-font-size: 63.75px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-63-75-font-style: normal;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-63-75-font-weight: 800;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-63-75-letter-spacing: 0px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-gilroy-extrabold-63-75-line-height: 78px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-medium-14-font-family: "Inter", Helvetica;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-medium-14-font-size: 14px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-medium-14-font-style: normal;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-medium-14-font-weight: 500;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-medium-14-letter-spacing: 0px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-medium-14-line-height: 16px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-medium-16-font-family: "Inter", Helvetica;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-medium-16-font-size: 16px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-medium-16-font-style: normal;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-medium-16-font-weight: 500;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-medium-16-letter-spacing: 0px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-medium-16-line-height: 16px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-medium-17-font-family: "Inter", Helvetica;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-medium-17-font-size: 17px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-medium-17-font-style: normal;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-medium-17-font-weight: 500;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-medium-17-letter-spacing: 0px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-medium-17-line-height: 24px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-regular-12-font-family: "Inter", Helvetica;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-regular-12-font-size: 12px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-regular-12-font-style: normal;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-regular-12-font-weight: 400;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-regular-12-letter-spacing: 0px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-regular-12-line-height: 12px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-regular-16-font-family: "Inter", Helvetica;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-regular-16-font-size: 16px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-regular-16-font-style: normal;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-regular-16-font-weight: 400;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-regular-16-letter-spacing: 0px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-regular-16-line-height: normal;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-regular-16-underline-font-family: "Inter", Helvetica;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-regular-16-underline-font-size: 16px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-regular-16-underline-font-style: normal;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-regular-16-underline-font-weight: 400;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-regular-16-underline-letter-spacing: 0px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-regular-16-underline-line-height: 24px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-regular-18-font-family: "Inter", Helvetica;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-regular-18-font-size: 18px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-regular-18-font-style: normal;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-regular-18-font-weight: 400;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-regular-18-letter-spacing: 0px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-regular-18-line-height: 24px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-semi-bold-16-font-family: "Inter", Helvetica;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-semi-bold-16-font-size: 16px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-semi-bold-16-font-style: normal;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-semi-bold-16-font-weight: 600;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-semi-bold-16-letter-spacing: 0px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-semi-bold-16-line-height: 24px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-semi-bold-18-font-family: "Inter", Helvetica;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-semi-bold-18-font-size: 18px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-semi-bold-18-font-style: normal;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-semi-bold-18-font-weight: 600;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-semi-bold-18-letter-spacing: 0px;
  --matcha-xyz-about-matcha-dex-aggregator-1024x1820-default-inter-semi-bold-18-line-height: 24px;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-gilroy-extrabold-19-84-font-family: "Gilroy", Helvetica;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-gilroy-extrabold-19-84-font-size: 19.8438px;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-gilroy-extrabold-19-84-font-style: normal;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-gilroy-extrabold-19-84-font-weight: 800;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-gilroy-extrabold-19-84-letter-spacing: 0px;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-gilroy-extrabold-19-84-line-height: 56px;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-gilroy-extrabold-35-72-font-family: "Gilroy", Helvetica;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-gilroy-extrabold-35-72-font-size: 35.7188px;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-gilroy-extrabold-35-72-font-style: normal;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-gilroy-extrabold-35-72-font-weight: 800;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-gilroy-extrabold-35-72-letter-spacing: 0px;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-gilroy-extrabold-35-72-line-height: 38px;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-gilroy-extrabold-35-86-font-family: "Gilroy", Helvetica;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-gilroy-extrabold-35-86-font-size: 35.8594px;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-gilroy-extrabold-35-86-font-style: normal;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-gilroy-extrabold-35-86-font-weight: 800;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-gilroy-extrabold-35-86-letter-spacing: 0px;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-gilroy-extrabold-35-86-line-height: 38px;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-gilroy-extrabold-63-5-font-family: "Gilroy", Helvetica;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-gilroy-extrabold-63-5-font-size: 63.5px;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-gilroy-extrabold-63-5-font-style: normal;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-gilroy-extrabold-63-5-font-weight: 800;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-gilroy-extrabold-63-5-letter-spacing: 0px;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-gilroy-extrabold-63-5-line-height: 78px;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-medium-14-font-family: "Inter", Helvetica;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-medium-14-font-size: 14px;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-medium-14-font-style: normal;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-medium-14-font-weight: 500;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-medium-14-letter-spacing: 0px;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-medium-14-line-height: 16px;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-medium-16-font-family: "Inter", Helvetica;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-medium-16-font-size: 16px;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-medium-16-font-style: normal;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-medium-16-font-weight: 500;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-medium-16-letter-spacing: 0px;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-medium-16-line-height: 16px;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-font-family: "Inter", Helvetica;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-font-size: 16px;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-font-style: normal;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-font-weight: 400;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-letter-spacing: 0px;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-line-height: normal;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-semi-bold-16-font-family: "Inter", Helvetica;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-semi-bold-16-font-size: 16px;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-semi-bold-16-font-style: normal;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-semi-bold-16-font-weight: 600;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-semi-bold-16-letter-spacing: 0px;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-semi-bold-16-line-height: 32px;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-semi-bold-18-font-family: "Inter", Helvetica;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-semi-bold-18-font-size: 18px;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-semi-bold-18-font-style: normal;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-semi-bold-18-font-weight: 600;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-semi-bold-18-letter-spacing: 0px;
  --matcha-xyz-cross-chain-swaps-1024x1820-default-inter-semi-bold-18-line-height: 24px;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-19-84-font-family: "Gilroy", Helvetica;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-19-84-font-size: 19.8438px;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-19-84-font-style: normal;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-19-84-font-weight: 800;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-19-84-letter-spacing: 0px;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-19-84-line-height: 56px;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-35-58-font-family: "Gilroy", Helvetica;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-35-58-font-size: 35.5781px;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-35-58-font-style: normal;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-35-58-font-weight: 800;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-35-58-letter-spacing: 0px;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-35-58-line-height: 38px;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-35-72-font-family: "Gilroy", Helvetica;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-35-72-font-size: 35.7188px;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-35-72-font-style: normal;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-35-72-font-weight: 800;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-35-72-letter-spacing: 0px;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-35-72-line-height: 38px;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-35-86-font-family: "Gilroy", Helvetica;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-35-86-font-size: 35.8594px;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-35-86-font-style: normal;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-35-86-font-weight: 800;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-35-86-letter-spacing: 0px;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-35-86-line-height: 38px;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-63-63-font-family: "Gilroy", Helvetica;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-63-63-font-size: 63.625px;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-63-63-font-style: normal;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-63-63-font-weight: 800;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-63-63-letter-spacing: 0px;
  --matcha-xyz-swap-tokens-1024x1820-default-gilroy-extrabold-63-63-line-height: 78px;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-medium-12-font-family: "Inter", Helvetica;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-medium-12-font-size: 12px;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-medium-12-font-style: normal;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-medium-12-font-weight: 500;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-medium-12-letter-spacing: 0px;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-medium-12-line-height: 16px;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-medium-14-font-family: "Inter", Helvetica;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-medium-14-font-size: 14px;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-medium-14-font-style: normal;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-medium-14-font-weight: 500;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-medium-14-letter-spacing: 0px;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-medium-14-line-height: 16px;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-medium-16-font-family: "Inter", Helvetica;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-medium-16-font-size: 16px;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-medium-16-font-style: normal;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-medium-16-font-weight: 500;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-medium-16-letter-spacing: 0px;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-medium-16-line-height: 16px;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-regular-12-font-family: "Inter", Helvetica;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-regular-12-font-size: 12px;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-regular-12-font-style: normal;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-regular-12-font-weight: 400;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-regular-12-letter-spacing: 0px;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-regular-12-line-height: 20px;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-regular-16-font-family: "Inter", Helvetica;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-regular-16-font-size: 16px;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-regular-16-font-style: normal;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-regular-16-font-weight: 400;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-regular-16-letter-spacing: 0px;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-regular-16-line-height: 24px;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-semi-bold-18-font-family: "Inter", Helvetica;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-semi-bold-18-font-size: 18px;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-semi-bold-18-font-style: normal;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-semi-bold-18-font-weight: 600;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-semi-bold-18-letter-spacing: 0px;
  --matcha-xyz-swap-tokens-1024x1820-default-inter-semi-bold-18-line-height: 24px;
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultathens-gray: #f1f2f4;
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultbig-stone-6: #1625460f;
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultbiloba-flower: #bdaef2;
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultblack: #000;
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultbright-gray-5: #3838520d;
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultbuttercup: #f0b90b;
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultcornflower-blue: #627eea;
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultdove-gray: #626262;
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultdusty-gray: #999;
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultfern: #5ab56c;
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultfirefly-8: #0f243814;
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultghost: #d1d1d6;
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultiron: #e4e4e7;
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultmanatee: #9299a6;
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultmedium-purple: #8247e5;
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultmidnight-blue: #02206e;
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultnavy-blue: #ff007a;
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultnero: #fff;
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultnero-02: #fff0;
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultnero-4: #ffffff0a;
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultporcelain: #e7e9eb;
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultportage: #8198ee;
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultsaffron: #f6cc40;
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultsantas-gray: #a0a0ab;
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultscorpion: #5a5a5a;
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultshark: #26272b;
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultshuttle-gray: #5e6773;
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultsilver-chalice: #a6a6a6;
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultsulu: #b5f07f;
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaulttropical-blue: #c0ccf7;
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaulttuna: #31353c;
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultwhite-lilac: #fcfcfd;
  --matchaxyzabout-matcha-dex-aggregator1024x1820defaultwoodsmoke: #17171b;
  --matchaxyzcross-chain-swaps1024x1820defaultathens-gray: #f1f2f4;
  --matchaxyzcross-chain-swaps1024x1820defaultathens-gray-60: #f7f7f899;
  --matchaxyzcross-chain-swaps1024x1820defaultbig-stone-6: #1625460f;
  --matchaxyzcross-chain-swaps1024x1820defaultbiloba-flower: #b4a6ea;
  --matchaxyzcross-chain-swaps1024x1820defaultblack: #000;
  --matchaxyzcross-chain-swaps1024x1820defaultblack-russian: #ff0420;
  --matchaxyzcross-chain-swaps1024x1820defaultblue-marguerite: #5f63c3;
  --matchaxyzcross-chain-swaps1024x1820defaultbright-gray-5: #3838520d;
  --matchaxyzcross-chain-swaps1024x1820defaultbright-sun: #fed440;
  --matchaxyzcross-chain-swaps1024x1820defaultbuttercup: #f0b90b;
  --matchaxyzcross-chain-swaps1024x1820defaultchantilly: #f4aef1;
  --matchaxyzcross-chain-swaps1024x1820defaultchateau-green: #3cb468;
  --matchaxyzcross-chain-swaps1024x1820defaultcinnabar: #e84142;
  --matchaxyzcross-chain-swaps1024x1820defaultcornflower-blue: #627eea;
  --matchaxyzcross-chain-swaps1024x1820defaultcrusoe: #0253ff;
  --matchaxyzcross-chain-swaps1024x1820defaultemerald: #3cbd68;
  --matchaxyzcross-chain-swaps1024x1820defaultfern: #5ab56c;
  --matchaxyzcross-chain-swaps1024x1820defaultfirefly-17: #0e1e2a2b;
  --matchaxyzcross-chain-swaps1024x1820defaultfirefly-8: #0f243814;
  --matchaxyzcross-chain-swaps1024x1820defaultgreen-haze: #02a863;
  --matchaxyzcross-chain-swaps1024x1820defaultiron: #e4e4e7;
  --matchaxyzcross-chain-swaps1024x1820defaultlimeade: #79afff;
  --matchaxyzcross-chain-swaps1024x1820defaultmanatee: #9299a6;
  --matchaxyzcross-chain-swaps1024x1820defaultnero: #fff;
  --matchaxyzcross-chain-swaps1024x1820defaultnero-20: #fff3;
  --matchaxyzcross-chain-swaps1024x1820defaultnero-4: #ffffff0a;
  --matchaxyzcross-chain-swaps1024x1820defaultnero-602: #fff9;
  --matchaxyzcross-chain-swaps1024x1820defaultnero-radial-pastel-green-pastel-green: #fff;
  --matchaxyzcross-chain-swaps1024x1820defaultperfume: #bdaff3;
  --matchaxyzcross-chain-swaps1024x1820defaultpickled-bluewood: #2c374b;
  --matchaxyzcross-chain-swaps1024x1820defaultpicton-blue: #24a2ee;
  --matchaxyzcross-chain-swaps1024x1820defaultporcelain: #e7e9eb;
  --matchaxyzcross-chain-swaps1024x1820defaultpurple-heart: #6f41d8;
  --matchaxyzcross-chain-swaps1024x1820defaultregent-st-blue: #98bcdc;
  --matchaxyzcross-chain-swaps1024x1820defaultsaffron: #f5cb40;
  --matchaxyzcross-chain-swaps1024x1820defaultsantas-gray: #a0a0ab;
  --matchaxyzcross-chain-swaps1024x1820defaultshark: #22262a;
  --matchaxyzcross-chain-swaps1024x1820defaultshuttle-gray: #5e6773;
  --matchaxyzcross-chain-swaps1024x1820defaultsulu: #b0ed80;
  --matchaxyzcross-chain-swaps1024x1820defaultturquoise-blue: #5ad2e1;
  --matchaxyzcross-chain-swaps1024x1820defaultwhite-lilac: #fcfcfd;
  --matchaxyzcross-chain-swaps1024x1820defaultwoodsmoke: #17171b;
  --matchaxyzcross-chain-swaps1024x1820defaultyellow-green: #ace87f;
  --matchaxyzswap-tokens1024x1820defaultapple: #5ea33b;
  --matchaxyzswap-tokens1024x1820defaultathens-gray: #f7f7f8;
  --matchaxyzswap-tokens1024x1820defaultathens-gray-60: #f7f7f899;
  --matchaxyzswap-tokens1024x1820defaultbig-stone-6: #1625460f;
  --matchaxyzswap-tokens1024x1820defaultblack: #000;
  --matchaxyzswap-tokens1024x1820defaultblack-12: #0000001f;
  --matchaxyzswap-tokens1024x1820defaultblack-russian: #ff0420;
  --matchaxyzswap-tokens1024x1820defaultbuttercup: #f0b90b;
  --matchaxyzswap-tokens1024x1820defaultchateau-green: #39a150;
  --matchaxyzswap-tokens1024x1820defaultcinnabar: #e84142;
  --matchaxyzswap-tokens1024x1820defaultcornflower-blue: #627eea;
  --matchaxyzswap-tokens1024x1820defaultcrusoe: #0253ff;
  --matchaxyzswap-tokens1024x1820defaultfern: #5ab56c;
  --matchaxyzswap-tokens1024x1820defaultjapanese-laurel: #1969ff;
  --matchaxyzswap-tokens1024x1820defaultmalachite-6: #00cb200f;
  --matchaxyzswap-tokens1024x1820defaultmanatee: #9299a6;
  --matchaxyzswap-tokens1024x1820defaultmariner: #2775ca;
  --matchaxyzswap-tokens1024x1820defaultnero: #fff;
  --matchaxyzswap-tokens1024x1820defaultnero-20: #fff3;
  --matchaxyzswap-tokens1024x1820defaultnero-4: #ffffff0a;
  --matchaxyzswap-tokens1024x1820defaultnero-602: #fff9;
  --matchaxyzswap-tokens1024x1820defaultperfume: #beaff3;
  --matchaxyzswap-tokens1024x1820defaultpickled-bluewood: #2c374b;
  --matchaxyzswap-tokens1024x1820defaultpicton-blue: #24a2ee;
  --matchaxyzswap-tokens1024x1820defaultporcelain: #e7e9eb;
  --matchaxyzswap-tokens1024x1820defaultpurple-heart: #6f41d8;
  --matchaxyzswap-tokens1024x1820defaultregent-st-blue: #98bcdc;
  --matchaxyzswap-tokens1024x1820defaultsaffron-mango: #fbcc5c;
  --matchaxyzswap-tokens1024x1820defaultsantas-gray: #a0a0ab;
  --matchaxyzswap-tokens1024x1820defaultshamrock: #35d07f;
  --matchaxyzswap-tokens1024x1820defaultshark: #22262a;
  --matchaxyzswap-tokens1024x1820defaultshuttle-gray: #5e6773;
  --matchaxyzswap-tokens1024x1820defaultsulu: #b2ef80;
  --matchaxyzswap-tokens1024x1820defaultwhite-lilac: #fcfcfd;
  --matchaxyzswap-tokens1024x1820defaultwoodsmoke: #17171c;
  --modalmodal-overlay: #020d1730;
  --neutral-0: #fff;
  --neutral-100: #eff0f3;
  --neutral-200: #e0e1e4;
  --neutral-300: #cfced2;
  --neutral-50: #f7f8fa;
  --neutral-700: #393c3f;
  --neutral-800: #1d2022;
  --neutral-900: #121315;
  --paragraph-large-font-family: "Inter", Helvetica;
  --paragraph-large-font-size: 18px;
  --paragraph-large-font-style: normal;
  --paragraph-large-font-weight: 400;
  --paragraph-large-letter-spacing: -.27px;
  --paragraph-large-line-height: 24px;
  --paragraph-medium-font-family: "Inter", Helvetica;
  --paragraph-medium-font-size: 16px;
  --paragraph-medium-font-style: normal;
  --paragraph-medium-font-weight: 400;
  --paragraph-medium-letter-spacing: -.176px;
  --paragraph-medium-line-height: 24px;
  --paragraph-small-font-family: "Inter", Helvetica;
  --paragraph-small-font-size: 14px;
  --paragraph-small-font-style: normal;
  --paragraph-small-font-weight: 400;
  --paragraph-small-letter-spacing: -.084px;
  --paragraph-small-line-height: 20px;
  --paragraph-x-large-font-family: "Inter", Helvetica;
  --paragraph-x-large-font-size: 24px;
  --paragraph-x-large-font-style: normal;
  --paragraph-x-large-font-weight: 400;
  --paragraph-x-large-letter-spacing: -.36px;
  --paragraph-x-large-line-height: 32px;
  --paragraph-x-small-font-family: "Inter", Helvetica;
  --paragraph-x-small-font-size: 12px;
  --paragraph-x-small-font-style: normal;
  --paragraph-x-small-font-weight: 400;
  --paragraph-x-small-letter-spacing: 0px;
  --paragraph-x-small-line-height: 16px;
  --pick-theme-primary-base: var(--foundations-blue-base);
  --pick-theme-primary-dark: var(--foundations-blue-dark);
  --pick-theme-primary-darker: var(--foundations-blue-darker);
  --pick-theme-primary-light: var(--foundations-blue-light);
  --pick-theme-primary-lighter: var(--foundations-blue-lighter);
  --radio-checkbox-shadow-active-bg: ;
  --radio-checkbox-shadow-default: 0px 2px 2px 0px #1b1c1d1f;
  --radio-checkbox-shadow-disabled: ;
  --radio-checkbox-shadow-radio-dot-active: 0px 2px 2px 0px #1b1c1d1f;
  --reddarker: #bc1010;
  --rednormal: #ef4444;
  --regular-shadow-2x-large: 0px 40px 96px -8px #585c5f33;
  --regular-shadow-large: 0px 16px 40px -8px #585c5f29;
  --regular-shadow-medium: 0px 16px 32px -12px #585c5f1a;
  --regular-shadow-small: 0px 2px 4px 0px #1b1c1d0a;
  --regular-shadow-x-large: 0px 24px 56px -4px #585c5f29;
  --regular-shadow-x-small: 0px 1px 2px 0px #e4e5e73d;
  --small-elements: 0px 1px 2px 0px #1018280d;
  --subheading-2x-small-font-family: "Inter", Helvetica;
  --subheading-2x-small-font-size: 11px;
  --subheading-2x-small-font-style: normal;
  --subheading-2x-small-font-weight: 500;
  --subheading-2x-small-letter-spacing: .22px;
  --subheading-2x-small-line-height: 12px;
  --subheading-medium-font-family: "Inter", Helvetica;
  --subheading-medium-font-size: 16px;
  --subheading-medium-font-style: normal;
  --subheading-medium-font-weight: 500;
  --subheading-medium-letter-spacing: .96px;
  --subheading-medium-line-height: 24px;
  --subheading-small-font-family: "Inter", Helvetica;
  --subheading-small-font-size: 14px;
  --subheading-small-font-style: normal;
  --subheading-small-font-weight: 500;
  --subheading-small-letter-spacing: .84px;
  --subheading-small-line-height: 20px;
  --subheading-x-small-font-family: "Inter", Helvetica;
  --subheading-x-small-font-size: 12px;
  --subheading-x-small-font-style: normal;
  --subheading-x-small-font-weight: 500;
  --subheading-x-small-letter-spacing: .48px;
  --subheading-x-small-line-height: 16px;
  --title-h1-title-font-family: "Inter Display", Helvetica;
  --title-h1-title-font-size: 56px;
  --title-h1-title-font-style: normal;
  --title-h1-title-font-weight: 500;
  --title-h1-title-letter-spacing: -.56px;
  --title-h1-title-line-height: 64px;
  --title-h2-title-font-family: "Inter Display", Helvetica;
  --title-h2-title-font-size: 48px;
  --title-h2-title-font-style: normal;
  --title-h2-title-font-weight: 500;
  --title-h2-title-letter-spacing: -.48px;
  --title-h2-title-line-height: 56px;
  --title-h3-title-font-family: "Inter Display", Helvetica;
  --title-h3-title-font-size: 40px;
  --title-h3-title-font-style: normal;
  --title-h3-title-font-weight: 500;
  --title-h3-title-letter-spacing: -.4px;
  --title-h3-title-line-height: 48px;
  --title-h4-title-font-family: "Inter Display", Helvetica;
  --title-h4-title-font-size: 32px;
  --title-h4-title-font-style: normal;
  --title-h4-title-font-weight: 500;
  --title-h4-title-letter-spacing: 0px;
  --title-h4-title-line-height: 40px;
  --title-h5-title-font-family: "Inter Display", Helvetica;
  --title-h5-title-font-size: 24px;
  --title-h5-title-font-style: normal;
  --title-h5-title-font-weight: 500;
  --title-h5-title-letter-spacing: 0px;
  --title-h5-title-line-height: 32px;
  --title-h6-title-font-family: "Inter Display", Helvetica;
  --title-h6-title-font-size: 20px;
  --title-h6-title-font-style: normal;
  --title-h6-title-font-weight: 500;
  --title-h6-title-letter-spacing: 0px;
  --title-h6-title-line-height: 28px;
  --toggle-shadow-switch-toggle-active: 0px 2px 4px 0px #1b1c1d05, 0px 6px 10px 0px #1b1c1d0f;
  --toggle-shadow-toggle-active: 0px 2px 4px 0px #16266414, 0px 4px 8px 0px #16266414, 0px 6px 10px 0px #16266414;
  --toggle-shadow-toggle-active-bg: ;
  --toggle-shadow-toggle-default: 0px 2px 4px 0px #1b1c1d05, 0px 6px 10px 0px #1b1c1d0f;
  --toggle-shadow-toggle-default-bg: ;
  --tokens-bg-soft-200: var(--foundations-neutral-200);
  --tokens-bg-strong-900: var(--foundations-neutral-900);
  --tokens-bg-surface-700: var(--foundations-neutral-700);
  --tokens-bg-weak-100: var(--foundations-neutral-100);
  --tokens-bg-white-0: var(--foundations-neutral-0);
  --tokens-icon-disabled-300: var(--foundations-neutral-300);
  --tokens-icon-soft-400: var(--foundations-neutral-400);
  --tokens-icon-strong-900: var(--foundations-neutral-900);
  --tokens-icon-sub-500: var(--foundations-neutral-500);
  --tokens-icon-white-0: var(--foundations-neutral-0);
  --tokens-state-away: var(--foundations-yellow-base);
  --tokens-state-error: var(--foundations-red-base);
  --tokens-state-feature: var(--foundations-purple-base);
  --tokens-state-information: var(--foundations-blue-base);
  --tokens-state-neutral: var(--foundations-neutral-400);
  --tokens-state-success: var(--foundations-green-base);
  --tokens-state-verified: var(--foundations-teal-base);
  --tokens-state-warning: var(--foundations-orange-base);
  --tokens-stroke-disabled-100: var(--foundations-neutral-100);
  --tokens-stroke-soft-200: var(--foundations-neutral-200);
  --tokens-stroke-strong-900: var(--foundations-neutral-900);
  --tokens-stroke-sub-300: var(--foundations-neutral-300);
  --tokens-stroke-white-0: var(--foundations-neutral-0);
  --tokens-text-disabled-300: var(--foundations-neutral-300);
  --tokens-text-main-900: var(--foundations-neutral-900);
  --tokens-text-soft-400: var(--foundations-neutral-400);
  --tokens-text-sub-500: var(--foundations-neutral-500);
  --tokens-text-white-0: var(--foundations-neutral-0);
  --tooltip-shadow-default-light: 0px 1px 2px 0px #e4e5e73d, 0px 12px 24px 0px #868c981f;
  --typography-action-font-family: "Inter", Helvetica;
  --typography-action-font-size: 14px;
  --typography-action-font-style: normal;
  --typography-action-font-weight: 500;
  --typography-action-letter-spacing: -.14px;
  --typography-action-line-height: 24px;
  --www-google-com-arial-regular-20-8-font-family: "Arial", Helvetica;
  --www-google-com-arial-regular-20-8-font-size: 20.7969px;
  --www-google-com-arial-regular-20-8-font-style: normal;
  --www-google-com-arial-regular-20-8-font-weight: 400;
  --www-google-com-arial-regular-20-8-letter-spacing: 0px;
  --www-google-com-arial-regular-20-8-line-height: 24px;
  --www-google-com-inter-regular-12-font-family: "Inter", Helvetica;
  --www-google-com-inter-regular-12-font-size: 12px;
  --www-google-com-inter-regular-12-font-style: normal;
  --www-google-com-inter-regular-12-font-weight: 400;
  --www-google-com-inter-regular-12-letter-spacing: 0px;
  --www-google-com-inter-regular-12-line-height: 24px;
  --www-google-com-inter-regular-9-13-font-family: "Inter", Helvetica;
  --www-google-com-inter-regular-9-13-font-size: 9.125px;
  --www-google-com-inter-regular-9-13-font-style: normal;
  --www-google-com-inter-regular-9-13-font-weight: 400;
  --www-google-com-inter-regular-9-13-letter-spacing: 0px;
  --www-google-com-inter-regular-9-13-line-height: 16px;
  --www-google-com-inter-regular-9-font-family: "Inter", Helvetica;
  --www-google-com-inter-regular-9-font-size: 9px;
  --www-google-com-inter-regular-9-font-style: normal;
  --www-google-com-inter-regular-9-font-weight: 400;
  --www-google-com-inter-regular-9-letter-spacing: 0px;
  --www-google-com-inter-regular-9-line-height: 18px;
  --www-google-com-roboto-bold-11-upper-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-bold-11-upper-font-size: 11px;
  --www-google-com-roboto-bold-11-upper-font-style: normal;
  --www-google-com-roboto-bold-11-upper-font-weight: 700;
  --www-google-com-roboto-bold-11-upper-letter-spacing: .8px;
  --www-google-com-roboto-bold-11-upper-line-height: 16px;
  --www-google-com-roboto-bold-12-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-bold-12-font-size: 12px;
  --www-google-com-roboto-bold-12-font-style: normal;
  --www-google-com-roboto-bold-12-font-weight: 700;
  --www-google-com-roboto-bold-12-letter-spacing: .3px;
  --www-google-com-roboto-bold-12-line-height: 16px;
  --www-google-com-roboto-medium-11-upper-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-medium-11-upper-font-size: 11px;
  --www-google-com-roboto-medium-11-upper-font-style: normal;
  --www-google-com-roboto-medium-11-upper-font-weight: 500;
  --www-google-com-roboto-medium-11-upper-letter-spacing: .8px;
  --www-google-com-roboto-medium-11-upper-line-height: 18px;
  --www-google-com-roboto-medium-12-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-medium-12-font-size: 12px;
  --www-google-com-roboto-medium-12-font-style: normal;
  --www-google-com-roboto-medium-12-font-weight: 500;
  --www-google-com-roboto-medium-12-letter-spacing: .3px;
  --www-google-com-roboto-medium-12-line-height: 16px;
  --www-google-com-roboto-medium-12-upper-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-medium-12-upper-font-size: 12px;
  --www-google-com-roboto-medium-12-upper-font-style: normal;
  --www-google-com-roboto-medium-12-upper-font-weight: 500;
  --www-google-com-roboto-medium-12-upper-letter-spacing: .8px;
  --www-google-com-roboto-medium-12-upper-line-height: 16px;
  --www-google-com-roboto-medium-13-63-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-medium-13-63-font-size: 13.625px;
  --www-google-com-roboto-medium-13-63-font-style: normal;
  --www-google-com-roboto-medium-13-63-font-weight: 500;
  --www-google-com-roboto-medium-13-63-letter-spacing: .1px;
  --www-google-com-roboto-medium-13-63-line-height: 24px;
  --www-google-com-roboto-medium-13-78-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-medium-13-78-font-size: 13.7813px;
  --www-google-com-roboto-medium-13-78-font-style: normal;
  --www-google-com-roboto-medium-13-78-font-weight: 500;
  --www-google-com-roboto-medium-13-78-letter-spacing: .25px;
  --www-google-com-roboto-medium-13-78-line-height: 20px;
  --www-google-com-roboto-medium-13-89-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-medium-13-89-font-size: 13.8906px;
  --www-google-com-roboto-medium-13-89-font-style: normal;
  --www-google-com-roboto-medium-13-89-font-weight: 500;
  --www-google-com-roboto-medium-13-89-letter-spacing: .25px;
  --www-google-com-roboto-medium-13-89-line-height: 20px;
  --www-google-com-roboto-medium-14-25-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-medium-14-25-font-size: 14.25px;
  --www-google-com-roboto-medium-14-25-font-style: normal;
  --www-google-com-roboto-medium-14-25-font-weight: 500;
  --www-google-com-roboto-medium-14-25-letter-spacing: .1px;
  --www-google-com-roboto-medium-14-25-line-height: 24px;
  --www-google-com-roboto-medium-14-38-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-medium-14-38-font-size: 14.375px;
  --www-google-com-roboto-medium-14-38-font-style: normal;
  --www-google-com-roboto-medium-14-38-font-weight: 500;
  --www-google-com-roboto-medium-14-38-letter-spacing: .1px;
  --www-google-com-roboto-medium-14-38-line-height: 24px;
  --www-google-com-roboto-medium-14-63-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-medium-14-63-font-size: 14.625px;
  --www-google-com-roboto-medium-14-63-font-style: normal;
  --www-google-com-roboto-medium-14-63-font-weight: 500;
  --www-google-com-roboto-medium-14-63-letter-spacing: .1px;
  --www-google-com-roboto-medium-14-63-line-height: 24px;
  --www-google-com-roboto-medium-14-75-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-medium-14-75-font-size: 14.75px;
  --www-google-com-roboto-medium-14-75-font-style: normal;
  --www-google-com-roboto-medium-14-75-font-weight: 500;
  --www-google-com-roboto-medium-14-75-letter-spacing: .1px;
  --www-google-com-roboto-medium-14-75-line-height: 24px;
  --www-google-com-roboto-medium-14-88-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-medium-14-88-font-size: 14.875px;
  --www-google-com-roboto-medium-14-88-font-style: normal;
  --www-google-com-roboto-medium-14-88-font-weight: 500;
  --www-google-com-roboto-medium-14-88-letter-spacing: .1px;
  --www-google-com-roboto-medium-14-88-line-height: 24px;
  --www-google-com-roboto-medium-14-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-medium-14-font-size: 14px;
  --www-google-com-roboto-medium-14-font-style: normal;
  --www-google-com-roboto-medium-14-font-weight: 500;
  --www-google-com-roboto-medium-14-letter-spacing: .25px;
  --www-google-com-roboto-medium-14-line-height: 16px;
  --www-google-com-roboto-medium-15-13-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-medium-15-13-font-size: 15.125px;
  --www-google-com-roboto-medium-15-13-font-style: normal;
  --www-google-com-roboto-medium-15-13-font-weight: 500;
  --www-google-com-roboto-medium-15-13-letter-spacing: .1px;
  --www-google-com-roboto-medium-15-13-line-height: 24px;
  --www-google-com-roboto-medium-15-25-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-medium-15-25-font-size: 15.25px;
  --www-google-com-roboto-medium-15-25-font-style: normal;
  --www-google-com-roboto-medium-15-25-font-weight: 500;
  --www-google-com-roboto-medium-15-25-letter-spacing: .1px;
  --www-google-com-roboto-medium-15-25-line-height: 24px;
  --www-google-com-roboto-medium-15-38-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-medium-15-38-font-size: 15.375px;
  --www-google-com-roboto-medium-15-38-font-style: normal;
  --www-google-com-roboto-medium-15-38-font-weight: 500;
  --www-google-com-roboto-medium-15-38-letter-spacing: .1px;
  --www-google-com-roboto-medium-15-38-line-height: 24px;
  --www-google-com-roboto-medium-15-5-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-medium-15-5-font-size: 15.5px;
  --www-google-com-roboto-medium-15-5-font-style: normal;
  --www-google-com-roboto-medium-15-5-font-weight: 500;
  --www-google-com-roboto-medium-15-5-letter-spacing: .1px;
  --www-google-com-roboto-medium-15-5-line-height: 24px;
  --www-google-com-roboto-medium-15-63-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-medium-15-63-font-size: 15.625px;
  --www-google-com-roboto-medium-15-63-font-style: normal;
  --www-google-com-roboto-medium-15-63-font-weight: 500;
  --www-google-com-roboto-medium-15-63-letter-spacing: .1px;
  --www-google-com-roboto-medium-15-63-line-height: 24px;
  --www-google-com-roboto-medium-15-75-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-medium-15-75-font-size: 15.75px;
  --www-google-com-roboto-medium-15-75-font-style: normal;
  --www-google-com-roboto-medium-15-75-font-weight: 500;
  --www-google-com-roboto-medium-15-75-letter-spacing: .1px;
  --www-google-com-roboto-medium-15-75-line-height: 24px;
  --www-google-com-roboto-medium-15-88-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-medium-15-88-font-size: 15.875px;
  --www-google-com-roboto-medium-15-88-font-style: normal;
  --www-google-com-roboto-medium-15-88-font-weight: 500;
  --www-google-com-roboto-medium-15-88-letter-spacing: .1px;
  --www-google-com-roboto-medium-15-88-line-height: 24px;
  --www-google-com-roboto-medium-15-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-medium-15-font-size: 15px;
  --www-google-com-roboto-medium-15-font-style: normal;
  --www-google-com-roboto-medium-15-font-weight: 500;
  --www-google-com-roboto-medium-15-letter-spacing: .1px;
  --www-google-com-roboto-medium-15-line-height: 24px;
  --www-google-com-roboto-medium-16-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-medium-16-font-size: 16px;
  --www-google-com-roboto-medium-16-font-style: normal;
  --www-google-com-roboto-medium-16-font-weight: 500;
  --www-google-com-roboto-medium-16-letter-spacing: .1px;
  --www-google-com-roboto-medium-16-line-height: 24px;
  --www-google-com-roboto-regular-12-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-regular-12-font-size: 12px;
  --www-google-com-roboto-regular-12-font-style: normal;
  --www-google-com-roboto-regular-12-font-weight: 400;
  --www-google-com-roboto-regular-12-letter-spacing: .3px;
  --www-google-com-roboto-regular-12-line-height: 16px;
  --www-google-com-roboto-regular-13-78-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-regular-13-78-font-size: 13.7813px;
  --www-google-com-roboto-regular-13-78-font-style: normal;
  --www-google-com-roboto-regular-13-78-font-weight: 400;
  --www-google-com-roboto-regular-13-78-letter-spacing: .25px;
  --www-google-com-roboto-regular-13-78-line-height: 20px;
  --www-google-com-roboto-regular-13-89-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-regular-13-89-font-size: 13.8906px;
  --www-google-com-roboto-regular-13-89-font-style: normal;
  --www-google-com-roboto-regular-13-89-font-weight: 400;
  --www-google-com-roboto-regular-13-89-letter-spacing: .25px;
  --www-google-com-roboto-regular-13-89-line-height: 20px;
  --www-google-com-roboto-regular-14-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-regular-14-font-size: 14px;
  --www-google-com-roboto-regular-14-font-style: normal;
  --www-google-com-roboto-regular-14-font-weight: 400;
  --www-google-com-roboto-regular-14-letter-spacing: .25px;
  --www-google-com-roboto-regular-14-line-height: 20px;
  --www-google-com-roboto-regular-15-88-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-regular-15-88-font-size: 15.875px;
  --www-google-com-roboto-regular-15-88-font-style: normal;
  --www-google-com-roboto-regular-15-88-font-weight: 400;
  --www-google-com-roboto-regular-15-88-letter-spacing: 0px;
  --www-google-com-roboto-regular-15-88-line-height: normal;
  --www-google-com-roboto-regular-17-58-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-regular-17-58-font-size: 17.5781px;
  --www-google-com-roboto-regular-17-58-font-style: normal;
  --www-google-com-roboto-regular-17-58-font-weight: 400;
  --www-google-com-roboto-regular-17-58-letter-spacing: 0px;
  --www-google-com-roboto-regular-17-58-line-height: 24px;
  --www-google-com-roboto-regular-18-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-regular-18-font-size: 18px;
  --www-google-com-roboto-regular-18-font-style: normal;
  --www-google-com-roboto-regular-18-font-weight: 400;
  --www-google-com-roboto-regular-18-letter-spacing: 0px;
  --www-google-com-roboto-regular-18-line-height: 24px;
  --www-google-com-roboto-regular-24-font-family: "Roboto", Helvetica;
  --www-google-com-roboto-regular-24-font-size: 24px;
  --www-google-com-roboto-regular-24-font-style: normal;
  --www-google-com-roboto-regular-24-font-weight: 400;
  --www-google-com-roboto-regular-24-letter-spacing: 0px;
  --www-google-com-roboto-regular-24-line-height: 32px;
  --wwwgooglecomathens-gray: #e8eaed;
  --wwwgooglecombamboo: #db5901;
  --wwwgooglecomblack: #000;
  --wwwgooglecomblack-87: #000000de;
  --wwwgooglecomcalifornia: #f29900;
  --wwwgooglecomcape-cod: #3c4043;
  --wwwgooglecomchateau-green: #34a853;
  --wwwgooglecomcinnabar: #ea4335;
  --wwwgooglecomcornflower-blue: #4285f4;
  --wwwgooglecomcrimson: #ed161e;
  --wwwgooglecomdenim: #1967d2;
  --wwwgooglecomdove-gray: #666;
  --wwwgooglecomindochine: #c26c03;
  --wwwgooglecomiron: #dadce0;
  --wwwgooglecomjewel: #188038;
  --wwwgooglecommantis: #82cc52;
  --wwwgooglecomnero: #fff;
  --wwwgooglecomnero-02: #fff0;
  --wwwgooglecomoslo-gray: #80868b;
  --wwwgooglecompanache: #e6f4ea;
  --wwwgooglecomporcelain: #f1f3f4;
  --wwwgooglecomprovincial-pink: #fce8e6;
  --wwwgooglecompunch: #d93025;
  --wwwgooglecomroyal-blue: #1a73e8;
  --wwwgooglecomroyal-blue-10: #1c74e91a;
  --wwwgooglecomselective-yellow: #fbbc05;
  --wwwgooglecomshark: #202124;
  --wwwgooglecomshuttle-gray: #5f6368;
  --wwwgooglecomsilver-sand: #bdc1c6;
  --wwwgooglecomtamarillo: #a50e0e;
  --wwwgooglecomthunderbird: #c5221f;
  --wwwgooglecomzumthor: #e8f0fe;
}

[data-pick-theme-mode="blue"] {
  --pick-theme-primary-base: var(--foundations-blue-base);
  --pick-theme-primary-dark: var(--foundations-blue-dark);
  --pick-theme-primary-darker: var(--foundations-blue-darker);
  --pick-theme-primary-light: var(--foundations-blue-light);
  --pick-theme-primary-lighter: var(--foundations-blue-lighter);
}

[data-pick-theme-mode="purple"] {
  --pick-theme-primary-base: var(--foundations-purple-base);
  --pick-theme-primary-dark: var(--foundations-purple-dark);
  --pick-theme-primary-darker: var(--foundations-purple-darker);
  --pick-theme-primary-light: var(--foundations-purple-light);
  --pick-theme-primary-lighter: var(--foundations-purple-lighter);
}

.popup {
  z-index: 1000;
  background-color: #00000080;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

.popup .frame-wrapper {
  width: 418px;
  height: 458px;
}

.popup .frame {
  background-color: #fff;
  border-radius: 26px;
  flex-direction: column;
  align-items: center;
  width: 418px;
  display: flex;
  position: relative;
  box-shadow: 0 2px 4px #00000005, 0 20px 70px #0000000f, 0 2px 12px #00000008;
}

.popup .div {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 24px;
  display: flex;
  position: relative;
}

.popup .text-wrapper {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  position: relative;
}

.popup .img {
  cursor: pointer;
  width: 16px;
  height: 16px;
  position: relative;
}

.popup .frame-2 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 32px;
  width: 100%;
  padding: 16px 24px 24px;
  display: flex;
  position: relative;
}

.popup .frame-3 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
  display: flex;
  position: relative;
}

.popup .frame-4 {
  flex: none;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;
  position: relative;
}

.popup .text-wrapper-2 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.popup .input {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  background-color: #f5f5f6;
  border: 0;
  border-radius: 10px;
  flex: none;
  align-self: stretch;
  width: 100%;
  height: 36px;
  padding: 0 12px;
  font-family: Inter, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.popup .p {
  color: #31353c;
  letter-spacing: 0;
  align-self: stretch;
  font-family: Inter, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  position: relative;
}

.popup .line {
  object-fit: cover;
  align-self: stretch;
  width: 100%;
  height: 1px;
  position: relative;
}

.popup .div-wrapper {
  cursor: pointer;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 24px;
  width: 100%;
  padding: 24px;
  display: flex;
  position: relative;
}

.popup .frame-5 {
  background-color: #17171c;
  border-radius: 50px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 52px;
  display: flex;
  position: relative;
  box-shadow: 0 1px 2px #0003;
}

.popup .text-wrapper-3 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.socials button {
  box-sizing: border-box;
  cursor: pointer;
  background: #fff;
  border: 1px solid #e2e4e9;
  border-radius: 16px;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  gap: 14px;
  width: 179px;
  height: 64px;
  padding: 12px;
  display: flex;
  box-shadow: 0 1px 2px #e4e5e73d;
}

.socials button :disabled {
  background: #a2a2a2;
}

.socials .outline {
  background: #fff;
  border: 1px solid #e2e4e9;
  border-radius: 999px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 40px;
  height: 40px;
  padding: 8px;
  display: flex;
  box-shadow: 0 1px 2px #e4e5e73d;
}

.socials .outline img {
  object-fit: cover;
  width: 24px;
  height: 24px;
}

.socials h2 {
  color: #0a0d14;
  margin: 0;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.socials {
  flex-direction: row;
  align-items: center;
  gap: 20px;
  padding: 16px 24px;
  display: flex;
}

.socials h1 {
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}

.socials-button {
  box-sizing: border-box;
  color: #000;
  cursor: pointer;
  background: #fff;
  border: 1px solid #e2e4e9;
  border-radius: 16px;
  flex-direction: row;
  align-items: center;
  gap: 14px;
  width: 179px;
  height: 64px;
  padding: 12px;
  text-decoration: none;
  display: inline-flex;
  box-shadow: 0 1px 2px #e4e5e73d;
}

.socials-button:disabled {
  background: #c5c5c5;
}

.socials-button:hover {
  background: #f5f5f6;
}

.socials-button h2 {
  color: inherit;
  margin: 0;
  font-size: 14px;
}

.socials-button-clicked {
  pointer-events: none;
  cursor: default;
  border: 2px solid #00ba6d;
}

.social-title {
  letter-spacing: 0;
  text-align: left;
  width: 100%;
  padding-left: 24px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}

.your-points-mobile {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.your-points-mobile .div-2 {
  background-color: #fff;
  position: relative;
  overflow: hidden;
}

.your-points-mobile .frame-6 {
  position: absolute;
}

.your-points-mobile .frame-7 {
  flex-direction: column;
  align-items: center;
  gap: 10px;
  height: 33px;
  display: inline-flex;
  position: relative;
}

.your-points-mobile .logo {
  width: 116px;
  height: 24px;
  position: relative;
}

.your-points-mobile .vector {
  width: 78px;
  height: 19px;
  position: absolute;
  top: 3px;
  left: 36px;
}

.your-points-mobile .logomark {
  width: 25px;
  height: 17px;
  position: absolute;
  top: 2px;
  left: 0;
}

.your-points-mobile .frame-8 {
  flex: none;
  align-items: center;
  gap: 16px;
  margin-bottom: -26px;
  display: inline-flex;
  position: relative;
}

.your-points-mobile .text-wrapper-4 {
  color: #5e6773;
  letter-spacing: -.16px;
  white-space: nowrap;
  width: 100%;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  position: relative;
}

.your-points-mobile .group {
  width: 6907px;
  height: 727px;
  position: absolute;
  top: 0;
  left: 0;
}

.your-points-mobile .overlap-group {
  background-image: url("crosschain-lines-svg.16a02b0c.svg");
  background-size: 100% 100%;
  width: 1440px;
  height: 611px;
  position: relative;
  top: 116px;
  left: 2896px;
}

.your-points-mobile .div-cda {
  background: radial-gradient(50% 50%, #fff 0% 29.69%, #ffffffa3 62.5%, #fff0 100%);
  width: 859px;
  height: 479px;
  position: relative;
  top: 13px;
  left: 274px;
}

.your-points-mobile .frame-9 {
  flex-direction: column;
  align-items: center;
  gap: 40px;
  display: inline-flex;
  position: absolute;
  top: 284px;
  left: 3379px;
}

.your-points-mobile .frame-10 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.your-points-mobile .div-3 {
  flex-direction: column;
  flex: none;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.your-points-mobile .text-wrapper-5 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: -.2px;
  text-align: center;
  text-fill-color: transparent;
  white-space: nowrap;
  background: linear-gradient(#0066db 0%, #0d6fdef5 37.5%, #bfdcfd61 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 100%;
  margin-top: -1px;
  font-family: Aeonik-Medium, Helvetica;
  font-size: 20px;
  font-weight: 500;
  line-height: 38px;
  position: relative;
  -webkit-background-clip: text !important;
}

.your-points-mobile .text-wrapper-6 {
  color: #000;
  letter-spacing: -.64px;
  width: 350px;
  font-family: Aeonik-Bold, Helvetica;
  font-size: 64px;
  font-weight: 700;
  line-height: 80px;
  position: relative;
}

.your-points-mobile .text-wrapper-7 {
  color: #5e6773;
  letter-spacing: -.16px;
  text-align: center;
  width: 474px;
  height: 49px;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  position: relative;
}

.your-points-mobile .button {
  all: unset;
  box-shadow: var(--small-elements);
  box-sizing: border-box;
  cursor: pointer;
  background-color: #121315;
  border-radius: 26px;
  justify-content: flex-end;
  align-items: center;
  height: 40px;
  padding: 10px 16px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.your-points-mobile .text-wrapper-8 {
  color: #fff;
  letter-spacing: -.14px;
  white-space: nowrap;
  cursor: pointer;
  width: 100%;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  position: relative;
}

.your-points-mobile .header {
  background-color: #0000;
  justify-content: space-between;
  align-items: center;
  width: 1440px;
  padding: 16px 32px;
  display: flex;
  position: absolute;
  top: 116px;
  left: 2896px;
}

.your-points-mobile .frame-11 {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 12px;
  display: inline-flex;
  position: relative;
}

.your-points-mobile .button-2 {
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
  background-color: #fff;
  border-radius: 26px;
  flex: none;
  justify-content: flex-end;
  align-items: center;
  height: 40px;
  padding: 10px 16px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.your-points-mobile .text-wrapper-9 {
  color: #000;
  letter-spacing: -.14px;
  white-space: nowrap;
  width: 100%;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
}

.your-points-mobile .right {
  justify-content: flex-end;
  align-items: center;
  width: 116px;
  display: flex;
  position: relative;
}

.your-points-mobile .button-3 {
  all: unset;
  box-shadow: var(--small-elements);
  box-sizing: border-box;
  background-color: #121315;
  border-radius: 26px;
  flex: none;
  justify-content: flex-end;
  align-items: center;
  height: 40px;
  padding: 10px 16px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.your-points-mobile .frame-12 {
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
  display: inline-flex;
  position: absolute;
  top: 616px;
  left: 2996px;
}

.your-points-mobile .overlap-group-wrapper {
  background-color: var(--matchaxyzcross-chain-swaps1024x1820defaultwhite-lilac);
  border: 1px solid #f1f2f4;
  border-radius: 36px 36px 12px 12px;
  width: 1240px;
  height: 350px;
  position: relative;
  overflow: hidden;
}

.your-points-mobile .heading-margin-wrapper {
  background-image: url("signup-lines-svg.48cc0eb5.svg");
  background-size: 100% 100%;
  height: 212px;
  position: relative;
  top: 42px;
}

.your-points-mobile .heading-margin {
  background: radial-gradient(50% 50%, #fcfcfd 0%, #fcfcfd00 100%);
  flex-direction: column;
  align-items: center;
  gap: 24px;
  display: inline-flex;
  position: relative;
  top: 12px;
  left: 329px;
}

.your-points-mobile .text-wrapper-10 {
  color: var(--matchaxyzcross-chain-swaps1024x1820defaultshark);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 100%;
  margin-top: -1px;
  font-family: Aeonik-Bold, Helvetica;
  font-size: 35.7px;
  font-weight: 700;
  line-height: 38px;
  position: relative;
}

.your-points-mobile .by-inputting-your {
  color: var(--matchaxyzcross-chain-swaps1024x1820defaultshuttle-gray);
  letter-spacing: 0;
  text-align: center;
  width: 100%;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  position: relative;
}

.your-points-mobile .input-2 {
  background-color: var(--matchaxyzcross-chain-swaps1024x1820defaultbright-gray-5);
  border: 1px solid;
  border-color: var(--matchaxyzcross-chain-swaps1024x1820defaultiron);
  border-radius: 12px;
  flex-direction: column;
  align-items: flex-start;
  width: 430px;
  max-width: 434px;
  height: 52px;
  padding: 16.25px 57px 15.75px 21px;
  display: flex;
  position: relative;
}

.your-points-mobile .input-2 .input-field {
  color: var(--matchaxyzcross-chain-swaps1024x1820defaultmanatee);
  font-family: var(--matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-font-family);
  font-size: var(--matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-font-size);
  font-style: var(--matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-font-style);
  font-weight: var(--matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-font-weight);
  letter-spacing: var(--matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-letter-spacing);
  line-height: var(--matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-line-height);
  white-space: nowrap;
  background: none;
  border: none;
  outline: none;
  flex-grow: 1;
  width: 100%;
  position: relative;
}

.your-points-mobile .input-2 .button-submit-email {
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.your-points-mobile .div-placeholder {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  padding: 1px 246px 0 0;
  display: flex;
  position: relative;
}

.your-points-mobile .text-wrapper-11 {
  color: var(--matchaxyzcross-chain-swaps1024x1820defaultmanatee);
  font-family: var(--matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-font-family);
  font-size: var(--matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-font-size);
  font-style: var(--matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-font-style);
  font-weight: var(--matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-font-weight);
  letter-spacing: var(--matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-letter-spacing);
  line-height: var(--matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-line-height);
  white-space: nowrap;
  width: 100%;
  margin-top: -1px;
  position: relative;
}

.your-points-mobile .button-submit-email {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 6px;
  left: 385px;
}

.your-points-mobile .div-kee {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  width: 1240px;
  max-width: 1240px;
  display: flex;
  position: relative;
}

.your-points-mobile .div-4 {
  background-color: var(--matchaxyzabout-matcha-dex-aggregator1024x1820defaultwhite-lilac);
  border: 1px solid #f1f2f4;
  border-radius: 12px;
  flex-direction: column;
  flex: 1;
  align-self: stretch;
  align-items: center;
  padding: 29px 59.22px 45px 59.21px;
  display: flex;
  position: relative;
}

.your-points-mobile .div-yevd {
  flex-direction: column;
  align-items: flex-start;
  height: 38px;
  padding: 0 0 7px;
  display: inline-flex;
  position: relative;
}

.your-points-mobile .div-keee {
  background-color: var(--matchaxyzabout-matcha-dex-aggregator1024x1820defaultnero);
  border: 2px solid;
  border-color: var(--matchaxyzabout-matcha-dex-aggregator1024x1820defaultiron);
  z-index: 1;
  border-radius: 72px;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 6px 15px 6px 8px;
  display: flex;
  position: relative;
}

.your-points-mobile .image {
  background-image: url("image.3e79cfd8.png");
  background-position: 50%;
  background-size: cover;
  width: 18px;
  height: 18px;
  position: relative;
}

.your-points-mobile .text-wrapper-12 {
  color: var(--matchaxyzabout-matcha-dex-aggregator1024x1820defaultblack);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 100%;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  position: relative;
}

.your-points-mobile .div-keeh {
  z-index: 0;
  align-self: stretch;
  width: 100%;
  height: 1px;
  position: relative;
}

.your-points-mobile .overlap-group-2 {
  width: 98px;
  height: 32px;
  position: relative;
  top: -24px;
  left: 12px;
}

.your-points-mobile .after {
  background-color: var(--matchaxyzabout-matcha-dex-aggregator1024x1820defaultnero);
  border: 2px solid;
  border-color: var(--matchaxyzabout-matcha-dex-aggregator1024x1820defaultiron);
  border-radius: 8px;
  width: 75px;
  height: 28px;
  position: absolute;
  top: 4px;
  left: 12px;
}

.your-points-mobile .before {
  background-color: var(--matchaxyzabout-matcha-dex-aggregator1024x1820defaultnero);
  border: 2px solid;
  border-color: var(--matchaxyzabout-matcha-dex-aggregator1024x1820defaultiron);
  border-radius: 8px;
  width: 98px;
  height: 28px;
  position: absolute;
  top: 0;
  left: 0;
}

.your-points-mobile .text-wrapper-13 {
  color: var(--matchaxyzabout-matcha-dex-aggregator1024x1820defaultblack);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 100%;
  margin-top: -1px;
  font-family: Aeonik-Bold, Helvetica;
  font-size: 63.6px;
  font-weight: 700;
  line-height: 78px;
  position: relative;
}

.your-points-mobile .text-wrapper-14 {
  color: var(--matchaxyzabout-matcha-dex-aggregator1024x1820defaultmanatee);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 100%;
  font-family: Inter, Helvetica;
  font-size: 17px;
  font-weight: 500;
  line-height: 24px;
  position: relative;
}

.your-points-mobile .div-5 {
  background-color: var(--matchaxyzabout-matcha-dex-aggregator1024x1820defaultwhite-lilac);
  border: 1px solid #f1f2f4;
  border-radius: 12px;
  flex-direction: column;
  flex: 1;
  align-self: stretch;
  align-items: center;
  padding: 29px 26.9px 45px 27px;
  display: flex;
  position: relative;
}

.your-points-mobile .div-keer {
  align-self: stretch;
  width: 100%;
  height: 111px;
  margin-top: -41px;
  position: relative;
}

.your-points-mobile .text-wrapper-15 {
  color: var(--matchaxyzabout-matcha-dex-aggregator1024x1820defaultblack);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 100%;
  font-family: Aeonik-Bold, Helvetica;
  font-size: 64px;
  font-weight: 700;
  line-height: 78px;
  position: relative;
}

.your-points-mobile .text-wrapper-16 {
  color: var(--matchaxyzabout-matcha-dex-aggregator1024x1820defaultmanatee);
  letter-spacing: 0;
  text-align: center;
  width: 128px;
  font-family: Inter, Helvetica;
  font-size: 17px;
  font-weight: 500;
  line-height: 24px;
  position: relative;
}

.your-points-mobile .div-6 {
  background-color: var(--matchaxyzabout-matcha-dex-aggregator1024x1820defaultwhite-lilac);
  border: 1px solid #f1f2f4;
  border-radius: 12px;
  flex-direction: column;
  flex: 1;
  align-self: stretch;
  align-items: center;
  padding: 29px 27px 45px;
  display: flex;
  position: relative;
}

.your-points-mobile .text-wrapper-17 {
  color: var(--matchaxyzabout-matcha-dex-aggregator1024x1820defaultblack);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 100%;
  margin-top: -1px;
  font-family: Aeonik-Bold, Helvetica;
  font-size: 64px;
  font-weight: 700;
  line-height: 78px;
  position: relative;
}

.your-points-mobile .overlap-wrapper {
  background-color: var(--matchaxyzabout-matcha-dex-aggregator1024x1820defaultwhite-lilac);
  border: 1px solid #f1f2f4;
  border-radius: 12px;
  flex: 1;
  align-self: stretch;
  position: relative;
}

.your-points-mobile .overlap {
  background-image: url("trade-volume-icon-svg-fill.44cd2e57.svg");
  background-size: 100% 100%;
  width: 295px;
  height: 214px;
}

.your-points-mobile .frame-13 {
  flex-direction: column;
  align-items: center;
  display: inline-flex;
  position: relative;
  top: 60px;
  left: 86px;
}

.your-points-mobile .section-wrapper {
  background-color: #fcfcfd;
  border: 1px solid #f1f2f4;
  border-radius: 12px 12px 36px 36px;
  width: 1240px;
  height: 476.64px;
  position: relative;
  overflow: hidden;
}

.your-points-mobile .overlap-2 {
  background-image: url("wave-lines-svg-fill.033d4af6.svg");
  background-size: 100% 100%;
  height: 476px;
  position: relative;
}

.your-points-mobile .list {
  justify-content: space-between;
  align-items: flex-end;
  width: 1118px;
  display: flex;
  position: absolute;
  top: 173px;
  left: 61px;
}

.your-points-mobile .item {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 16px;
  width: 324px;
  padding: 0 0 52px;
  display: flex;
  position: relative;
}

.your-points-mobile .SVG {
  width: 126px;
  height: 126px;
  position: relative;
}

.your-points-mobile .text-wrapper-18 {
  color: #5e6773;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.your-points-mobile .item-2 {
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 324px;
  display: flex;
  position: relative;
}

.your-points-mobile .item-3 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 16px;
  width: 324px;
  padding: 0 0 53px;
  display: flex;
  position: relative;
}

.your-points-mobile .SVG-2 {
  width: 126px;
  height: 125px;
  position: relative;
}

.your-points-mobile .heading {
  flex-direction: column;
  align-items: center;
  gap: 12px;
  display: inline-flex;
  position: absolute;
  top: 42px;
  left: 446px;
}

.your-points-mobile .airdrop-how-to-earn {
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  font-family: Aeonik-Bold, Helvetica;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  position: relative;
}

.your-points-mobile .span {
  color: #a0a0ab;
}

.your-points-mobile .text-wrapper-19 {
  color: #000;
}

.your-points-mobile .text-wrapper-20 {
  color: #5e6773;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 100%;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  position: relative;
}

.your-points-mobile .overlap-3 {
  position: absolute;
}

.your-points-mobile .overlap-group-3 {
  width: 859px;
  height: 612px;
  position: relative;
  top: 115px;
  left: 3170px;
}

.your-points-mobile .crosschain-lines-svg {
  width: 393px;
  height: 612px;
  position: absolute;
  top: 0;
  left: 186px;
}

.your-points-mobile .div-7 {
  background: radial-gradient(50% 50%, #fff 0% 29.69%, #ffffffa3 62.5%, #fff0 100%);
  width: 859px;
  height: 479px;
  position: absolute;
  top: 14px;
  left: 0;
}

.your-points-mobile .header-2 {
  background-color: #0000;
  justify-content: space-between;
  align-items: center;
  width: 393px;
  padding: 16px 24px;
  display: flex;
  position: absolute;
  top: 115px;
  left: 3356px;
}

.your-points-mobile .logo-2 {
  width: 96.67px;
  height: 20px;
  position: relative;
}

.your-points-mobile .vector-2 {
  width: 65px;
  height: 16px;
  position: absolute;
  top: 2px;
  left: 30px;
}

.your-points-mobile .logomark-2 {
  width: 20px;
  height: 14px;
  position: absolute;
  top: 2px;
  left: 0;
}

.your-points-mobile .right-2 {
  flex: none;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.your-points-mobile .buttons {
  background-color: var(--neutral-900);
  border-radius: 24px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  display: inline-flex;
  position: relative;
}

.your-points-mobile .label {
  color: var(--neutral-0);
  font-family: var(--typography-action-font-family);
  font-size: var(--typography-action-font-size);
  font-style: var(--typography-action-font-style);
  font-weight: var(--typography-action-font-weight);
  letter-spacing: var(--typography-action-letter-spacing);
  line-height: var(--typography-action-line-height);
  white-space: nowrap;
  width: 100%;
  margin-top: -1px;
  position: relative;
}

.your-points-mobile .button-4 {
  flex: none;
  margin-top: -1px;
  margin-bottom: -3px;
  margin-right: -2px;
  position: relative;
}

.your-points-mobile .frame-14 {
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 345px;
  display: flex;
  position: absolute;
  top: 263px;
  left: 3380px;
}

.your-points-mobile .text-wrapper-21 {
  color: #5e6773;
  letter-spacing: -.16px;
  text-align: center;
  align-self: stretch;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  position: relative;
}

.your-points-mobile .frame-15 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: absolute;
  top: 608px;
  left: 3380px;
}

.your-points-mobile .frame-16 {
  background-color: var(--matchaxyzcross-chain-swaps1024x1820defaultwhite-lilac);
  border: 1px solid #f1f2f4;
  border-radius: 36px 36px 12px 12px;
  width: 340px;
  height: 375px;
  position: relative;
  overflow: hidden;
}

.your-points-mobile .overlap-group-4 {
  height: 226px;
  position: relative;
  top: 35px;
}

.your-points-mobile .signup-lines-svg {
  width: 340px;
  height: 212px;
  position: absolute;
  top: 7px;
  left: 0;
}

.your-points-mobile .heading-margin-2 {
  background: radial-gradient(50% 50%, #fcfcfd 0%, #fcfcfd00 100%);
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 301px;
  height: 226px;
  display: flex;
  position: absolute;
  top: 0;
  left: 19px;
}

.your-points-mobile .text-wrapper-22 {
  color: var(--matchaxyzcross-chain-swaps1024x1820defaultshark);
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  font-family: Aeonik-Bold, Helvetica;
  font-size: 35.7px;
  font-weight: 700;
  line-height: 38px;
  position: relative;
}

.your-points-mobile .text-wrapper-23 {
  color: var(--matchaxyzcross-chain-swaps1024x1820defaultshuttle-gray);
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  position: relative;
}

.your-points-mobile .input-3 {
  background-color: var(--matchaxyzcross-chain-swaps1024x1820defaultbright-gray-5);
  border: 1px solid;
  border-color: var(--matchaxyzcross-chain-swaps1024x1820defaultiron);
  border-radius: 12px;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  max-width: 434px;
  height: 52px;
  padding: 16.25px 57px 15.75px 21px;
  display: flex;
  position: relative;
}

.your-points-mobile .input-3 .input-field {
  color: var(--matchaxyzcross-chain-swaps1024x1820defaultmanatee);
  font-family: var(--matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-font-family);
  font-size: var(--matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-font-size);
  font-style: var(--matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-font-style);
  font-weight: var(--matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-font-weight);
  letter-spacing: var(--matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-letter-spacing);
  line-height: var(--matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-line-height);
  white-space: nowrap;
  background: none;
  border: none;
  outline: none;
  flex-grow: 1;
  width: 100%;
  position: relative;
}

.your-points-mobile .text-wrapper-24 {
  color: var(--matchaxyzcross-chain-swaps1024x1820defaultmanatee);
  font-family: var(--matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-font-family);
  font-size: var(--matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-font-size);
  font-style: var(--matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-font-style);
  font-weight: var(--matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-font-weight);
  letter-spacing: var(--matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-letter-spacing);
  line-height: var(--matcha-xyz-cross-chain-swaps-1024x1820-default-inter-regular-16-line-height);
  white-space: nowrap;
  width: 100%;
  margin-top: -1px;
  margin-right: -86px;
  position: relative;
}

.your-points-mobile .button-submit-email-2 {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 6px;
  left: 256px;
}

.your-points-mobile .div-8 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 20px;
  width: 340px;
  max-width: 1240px;
  display: flex;
  position: relative;
}

.your-points-mobile .div-9 {
  background-color: var(--matchaxyzabout-matcha-dex-aggregator1024x1820defaultwhite-lilac);
  border: 1px solid #f1f2f4;
  border-radius: 12px;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  width: 100%;
  height: 214px;
  padding: 29px 59.22px 45px 59.21px;
  display: flex;
  position: relative;
}

.your-points-mobile .div-10 {
  background-color: var(--matchaxyzabout-matcha-dex-aggregator1024x1820defaultwhite-lilac);
  border: 1px solid #f1f2f4;
  border-radius: 12px;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  width: 100%;
  height: 214px;
  padding: 29px 26.9px 45px 27px;
  display: flex;
  position: relative;
}

.your-points-mobile .div-11 {
  background-color: var(--matchaxyzabout-matcha-dex-aggregator1024x1820defaultwhite-lilac);
  border: 1px solid #f1f2f4;
  border-radius: 12px;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  width: 100%;
  height: 214px;
  padding: 29px 27px 45px;
  display: flex;
  position: relative;
}

.your-points-mobile .div-12 {
  background-color: var(--matchaxyzabout-matcha-dex-aggregator1024x1820defaultwhite-lilac);
  border: 1px solid #f1f2f4;
  border-radius: 12px;
  align-self: stretch;
  width: 100%;
  height: 214px;
  position: relative;
}

.your-points-mobile .overlap-4 {
  background-image: url("trade-volume-icon-svg-fill-1.d6c80798.svg");
  background-size: 100% 100%;
  width: 340px;
  height: 214px;
}

.your-points-mobile .section-wrapper-2 {
  background-color: #fcfcfd;
  border: 1px solid #f1f2f4;
  border-radius: 12px 12px 36px 36px;
  width: 340px;
  height: 936px;
  position: relative;
  overflow: hidden;
}

.your-points-mobile .overlap-5 {
  width: 340px;
  height: 670px;
  position: absolute;
  top: 257px;
  left: 0;
}

.your-points-mobile .wave-lines-svg-fill {
  width: 340px;
  height: 476px;
  position: absolute;
  top: 162px;
  left: 0;
}

.your-points-mobile .list-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 8px;
}

.your-points-mobile .item-4 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 16px;
  width: 100%;
  display: flex;
  position: relative;
}

.your-points-mobile .text-wrapper-25 {
  color: #5e6773;
  letter-spacing: 0;
  text-align: center;
  width: 260px;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.your-points-mobile .item-5 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 16px;
  width: 324px;
  display: flex;
  position: relative;
}

.your-points-mobile .item-6 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding: 0 0 53px;
  display: flex;
  position: relative;
}

.your-points-mobile .heading-2 {
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: 279px;
  display: flex;
  position: absolute;
  top: 27px;
  left: 30px;
}

.your-points-mobile .text-wrapper-26 {
  color: #5e6773;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  position: relative;
}

.your-points-mobile .frame-17 {
  justify-content: space-between;
  align-items: center;
  width: 1240px;
  height: 33px;
  display: flex;
  position: relative;
}

.your-points-mobile .frame-18 {
  flex: none;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.your-points-mobile .user-code-container {
  position: relative;
}

.your-points-mobile .copy-button {
  all: unset;
  cursor: pointer;
  border-radius: 5px;
  width: 20px;
  height: 20px;
  padding: 5px 8px;
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
}

.your-points-mobile .input-container {
  justify-content: center;
  gap: 20px;
  display: flex;
}

._1nQ64q_header {
  background-color: var(--color-white);
  z-index: 10;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  padding: 5px 60px;
  display: flex;
  position: sticky;
  top: 0;
}

@media only screen and (width <= 960px) {
  ._1nQ64q_header {
    padding: 16px 24px;
  }
}

._1nQ64q_logo {
  height: 24px;
}

@media only screen and (width <= 960px) {
  ._1nQ64q_logo {
    height: 20px;
  }
}

._1nQ64q_logo-footer {
  opacity: .5;
}

._1nQ64q_nav {
  background-color: #fff;
  align-items: center;
  gap: 12px;
  display: flex;
}

@media only screen and (width <= 960px) {
  ._1nQ64q_nav {
    background: "white";
    pointer-events: none;
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
    padding: 0 24px 32px;
    transition: all .15s ease-in-out;
    position: fixed;
    inset: 72px 0 0;
    transform: scale(.95);
  }

  ._1nQ64q_nav-open {
    opacity: 1;
    pointer-events: all;
    transform: scale(1);
  }
}

._1nQ64q_nav-item {
  color: var(--color-dark);
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 500;
}

@media only screen and (width <= 960px) {
  ._1nQ64q_nav-item {
    border-bottom: 1px solid var(--color-gray-7);
    width: 100%;
    padding: 16px 0;
    font-size: 24px;
  }
}

._1nQ64q_nav-enter-app {
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  margin-top: auto;
  padding-top: 12px;
  padding-bottom: 12px;
  display: none;
}

._1nQ64q_nav-enter-app svg, ._1nQ64q_nav-enter-app img {
  background-color: #0000;
  height: 14px;
}

@media only screen and (width <= 960px) {
  ._1nQ64q_nav-enter-app {
    display: flex;
  }
}

._1nQ64q_actions {
  gap: 16px;
  display: flex;
}

@media only screen and (width <= 960px) {
  ._1nQ64q_actions {
    gap: 12px;
  }
}

._1nQ64q_desktop-menu {
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  display: flex;
}

@media only screen and (width <= 960px) {
  ._1nQ64q_desktop-menu {
    display: none;
  }
}

._1nQ64q_mobile-menu {
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  display: none;
}

@media only screen and (width <= 960px) {
  ._1nQ64q_mobile-menu {
    display: flex;
  }
}

._1nQ64q_mobile-menu img {
  background-color: #0000;
}

.buttons {
  border-radius: 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.buttons .instance-node {
  width: 20px !important;
  height: 20px !important;
  position: relative !important;
}

.buttons .label {
  font-family: var(--typography-action-font-family);
  font-size: var(--typography-action-font-size);
  font-style: var(--typography-action-font-style);
  font-weight: var(--typography-action-font-weight);
  letter-spacing: var(--typography-action-letter-spacing);
  line-height: var(--typography-action-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.buttons.disabled {
  background-color: var(--neutral-50);
}

.buttons.error.focus {
  background-color: var(--reddarker);
}

.buttons.off.off {
  padding: 8px 16px;
}

.buttons.hover.important {
  background-color: var(--neutral-800);
}

.buttons.hover.primary {
  background-color: var(--bluedarker);
}

.buttons.hover.blank {
  background-color: var(--neutral-50);
}

.buttons.hover.secondary {
  background-color: var(--neutral-0);
  border: 1px solid;
  border-color: var(--neutral-900);
}

.buttons.normal.secondary {
  background-color: var(--neutral-0);
  border: 1px solid;
  border-color: var(--neutral-200);
}

.buttons.blank.focus {
  background-color: var(--neutral-100);
}

.buttons.normal.primary {
  background-color: var(--bluenormal);
}

.buttons.important.normal {
  background-color: var(--neutral-900);
}

.buttons.important.focus {
  background-color: var(--neutral-700);
}

.buttons.on.off {
  padding: 8px 10px 8px 16px;
}

.buttons.focus.primary {
  background-color: var(--bluedarker);
  border: 1px solid;
  border-color: var(--bluenormal);
}

.buttons.error.normal {
  background-color: var(--neutral-0);
  border: 1px solid;
  border-color: var(--rednormal);
}

.buttons.focus.secondary {
  background-color: var(--neutral-50);
}

.buttons.hover.error {
  background-color: var(--rednormal);
}

.buttons.on.on {
  padding: 8px 10px;
}

.buttons.on.on.focus.secondary {
  border: 1px solid;
  border-color: var(--neutral-0);
}

.buttons.disabled .label {
  color: var(--neutral-300);
}

.buttons.secondary .label {
  margin-top: -.5px;
}

.buttons.important .label, .buttons.primary .label {
  margin-top: -1px;
}

.buttons.blank .label {
  margin-top: -.5px;
}

.buttons.error.focus .label {
  color: var(--neutral-0);
  margin-top: -1px;
}

.buttons.blank.normal .label {
  color: var(--neutral-900);
}

.buttons.hover.important .label, .buttons.hover.primary .label {
  color: var(--neutral-0);
}

.buttons.hover.blank .label, .buttons.hover.secondary .label, .buttons.normal.secondary .label, .buttons.blank.focus .label {
  color: var(--neutral-900);
}

.buttons.normal.primary .label, .buttons.important.focus .label, .buttons.important.normal .label, .buttons.focus.primary .label {
  color: var(--neutral-0);
}

.buttons.error.normal .label {
  color: var(--rednormal);
  margin-top: -.5px;
}

.buttons.focus.secondary .label {
  color: var(--neutral-900);
}

.buttons.error.disabled .label {
  margin-top: -.5px;
}

.buttons.hover.error .label {
  color: var(--neutral-0);
  margin-top: -1px;
}

.logo {
  width: 116px;
  height: 24px;
  position: relative;
}

.logo .vector {
  width: 78px;
  height: 19px;
  position: absolute;
  top: 3px;
  left: 36px;
}

.index {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.index .trade {
  background-color: #fff;
  position: relative;
  overflow: hidden;
}

.index .overlap-group {
  position: absolute;
  top: 0;
}

.index .liquidity-sources {
  object-fit: cover;
  width: 1133px;
  height: 500px;
  position: absolute;
}

.index .wrapper {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 345px;
  display: flex;
  position: absolute;
  top: 521px;
  left: 394px;
}

.index .top {
  flex: none;
  align-items: center;
  gap: 808px;
  display: inline-flex;
  position: relative;
}

.index .frame {
  flex: none;
  align-items: flex-start;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.index .heading-arbitrum-wrapper {
  flex: none;
  justify-content: center;
  align-items: flex-end;
  gap: 12px;
  display: inline-flex;
  position: relative;
}

.index .heading-arbitrum {
  color: #121315;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 24px;
  font-weight: 500;
  line-height: 38px;
  position: relative;
}

.index .div {
  background-color: #fff;
  border: 1px solid #e4e4e7;
  border-radius: 26px;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.index .frame-2 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  padding: 17px 32px;
  display: flex;
  position: relative;
}

.index .text-wrapper {
  color: #22262a;
  letter-spacing: -.18px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  position: relative;
}

.index .frame-3 {
  background-color: #f5f5f6;
  border-radius: 10px;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 36px;
  padding: 0 10px;
  display: flex;
  position: relative;
}

.index .svgexport {
  width: 16px;
  height: 16px;
  position: relative;
}

.index .text-wrapper-2 {
  color: #4c4c4c;
  letter-spacing: -.14px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.index .line {
  object-fit: cover;
  align-self: stretch;
  width: 100%;
  height: 1px;
  position: relative;
}

.index .frame-4 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 12px 32px;
  display: flex;
  position: relative;
}

.index .text-wrapper-3 {
  color: #5e6773;
  letter-spacing: -.14px;
  width: 48px;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  position: relative;
}

.index .text-wrapper-4, .index .text-wrapper-5 {
  color: #5e6773;
  letter-spacing: -.14px;
  flex: 1;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  position: relative;
}

.index .frame-5 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 14px 32px;
  display: flex;
  position: relative;
}

.index .text-wrapper-6 {
  color: #17171c;
  letter-spacing: 0;
  width: 48px;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
}

.index .text-wrapper-7 {
  color: #17171c;
  letter-spacing: 0;
  flex: 1;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
}

.index .text-wrapper-8 {
  color: #5e6773;
  letter-spacing: 0;
  flex: 1;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
}

.index .frame-6 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 6px;
  width: 100%;
  padding: 14px 32px;
  display: flex;
  position: relative;
}

.index .img {
  width: 36px;
  height: 36px;
  position: relative;
}

.index .div-wrapper {
  background-color: #0077ff1a;
  border: 1px solid #07f;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 36px;
  height: 36px;
  display: flex;
  position: relative;
}

.index .text-wrapper-9 {
  color: #17171c;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
}

.index .frame-7 {
  background-color: #3838520d;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 36px;
  height: 36px;
  display: flex;
  position: relative;
}

.index .frame-8 {
  background-color: #38385206;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 36px;
  height: 36px;
  display: flex;
  position: relative;
}

.index .frame-9 {
  width: 36px;
  height: 36px;
  margin-right: -7px;
  position: relative;
}

.index .rectangle {
  background: radial-gradient(50% 50%, #fff 62.5%, #fff0 100%);
  width: 414px;
  height: 391px;
  position: absolute;
  top: 138px;
  left: 360px;
}

.index .frame-10 {
  flex-direction: column;
  align-items: center;
  gap: 40px;
  display: inline-flex;
  position: absolute;
}

.index .frame-11 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.index .frame-12 {
  flex-direction: column;
  flex: none;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.index .text-wrapper-10 {
  -webkit-text-fill-color: transparent;
  color: #0000;
  letter-spacing: -.2px;
  text-align: center;
  text-fill-color: transparent;
  white-space: nowrap;
  background: linear-gradient(#0066db 0%, #0d6fdef5 37.5%, #bfdcfd61 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Aeonik-Medium, Helvetica;
  font-size: 20px;
  font-weight: 500;
  line-height: 38px;
  position: relative;
  -webkit-background-clip: text !important;
}

.index .text-wrapper-11 {
  color: #000;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-weight: 500;
  line-height: 80px;
  position: relative;
}

.index .p {
  color: #5e6773;
  letter-spacing: -.16px;
  text-align: center;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  position: relative;
}

.index .button {
  all: unset;
  box-shadow: var(--small-elements);
  box-sizing: border-box;
  cursor: pointer;
  background-color: #121315;
  border-radius: 26px;
  justify-content: flex-end;
  align-items: center;
  height: 40px;
  padding: 10px 16px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.index .text-wrapper-12 {
  color: #fff;
  letter-spacing: -.14px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  position: relative;
}

.index .header {
  background-color: #0000;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
}

.index .logo-instance {
  width: 25px !important;
  height: 17px !important;
  position: absolute !important;
  top: 2px !important;
  left: 0 !important;
}

.index .frame-13 {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 12px;
  display: inline-flex;
  position: relative;
}

.index .button-2 {
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
  background-color: #fff;
  border-radius: 26px;
  flex: none;
  justify-content: flex-end;
  align-items: center;
  height: 40px;
  padding: 10px 16px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.index .text-wrapper-13 {
  color: #000;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.index .right {
  justify-content: flex-end;
  align-items: center;
  width: 116px;
  display: flex;
  position: relative;
}

.index .button-3 {
  all: unset;
  box-shadow: var(--small-elements);
  box-sizing: border-box;
  cursor: pointer;
  background-color: #121315;
  border-radius: 26px;
  flex: none;
  justify-content: flex-end;
  align-items: center;
  height: 40px;
  padding: 10px 16px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.index .logo-2 {
  width: 96.67px !important;
  height: 20px !important;
}

.index .logo-3 {
  width: 65px !important;
  height: 16px !important;
  top: 2px !important;
  left: 30px !important;
}

.index .logo-4 {
  width: 20px !important;
  height: 14px !important;
  position: absolute !important;
  top: 2px !important;
  left: 0 !important;
}

.index .right-2 {
  flex: none;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.index .buttons-instance {
  flex: none !important;
}

.index .button-4 {
  flex: none;
  margin-top: -1px;
  margin-bottom: -3px;
  margin-right: -2px;
  position: relative;
}

.index .wrapper-2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 638px;
  display: flex;
  position: absolute;
  top: 494px;
  left: 248px;
}

.index .frame-wrapper {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 808px;
  width: 100%;
  display: flex;
  position: relative;
}

.index .frame-14 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 17px 32px;
  display: flex;
  position: relative;
}

.index .text-wrapper-14 {
  color: #22262a;
  letter-spacing: -.18px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  position: relative;
}

.index .frame-15 {
  background-color: #f5f5f6;
  border-radius: 10px;
  align-items: center;
  gap: 10px;
  width: 310px;
  height: 36px;
  padding: 0 10px;
  display: flex;
  position: relative;
}

.index .text-wrapper-15 {
  color: #5e6773;
  letter-spacing: -.14px;
  width: 120px;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  position: relative;
}

.index .text-wrapper-16 {
  color: #17171c;
  letter-spacing: 0;
  width: 120px;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
}

.index .frame-16 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 14px 32px;
  display: flex;
  position: relative;
}

.index .rectangle-2 {
  background: radial-gradient(50% 50%, #fff 62.5%, #fff0 100%);
  width: 545px;
  height: 308px;
  position: absolute;
  top: 132px;
  left: 488px;
}

.index .frame-17 {
  flex-direction: column;
  align-items: center;
  gap: 40px;
  display: inline-flex;
  position: absolute;
  top: 168px;
  left: 483px;
}

.index .text-wrapper-17 {
  color: #000;
  letter-spacing: -.64px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 64px;
  font-weight: 500;
  line-height: 80px;
  position: relative;
}

.index .text-wrapper-18 {
  color: #5e6773;
  letter-spacing: -.16px;
  text-align: center;
  width: 474px;
  height: 49px;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  position: relative;
}

.index .wrapper-3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 1240px;
  display: flex;
  position: absolute;
  top: 510px;
  left: 100px;
}

.index .frame-18 {
  flex: 1;
  align-items: center;
  gap: 8px;
  display: flex;
  position: relative;
}

.index .ellipse {
  background-color: #d9d9d9;
  border-radius: 8px;
  width: 16px;
  height: 16px;
  position: relative;
  box-shadow: 0 1px 2px #00000040;
}

.index .frame-19 {
  border-color: #f1f2f4;
  border-top-style: solid;
  border-top-width: 1px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: flex;
  position: absolute;
}

.index .frame-20 {
  align-items: center;
  height: 33px;
  position: relative;
}

.index .frame-21 {
  flex: none;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.index .text-wrapper-19 {
  color: #5e6773;
  letter-spacing: -.16px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  position: relative;
}

.page-number {
  cursor: pointer;
  transition: background-color .3s;
}

.page-number.active {
  color: #07f;
  background-color: #0077ff1a;
  border: 1px solid #07f;
}

.search-input {
  box-sizing: border-box;
  background-color: #f5f5f6;
  border: none;
  outline: none;
  width: 100%;
}

.search-input::placeholder {
  color: #000;
  opacity: 1;
}

.divider {
  object-fit: cover;
  background-color: #e4e4e7;
  align-self: stretch;
  width: 100%;
  height: 1px;
  position: relative;
}

@keyframes pulse {
  0% {
    background-color: #f5f5f6;
  }

  50% {
    background-color: #e4e4e7;
  }

  100% {
    background-color: #f5f5f6;
  }
}

.loading-spinner {
  border-radius: 10px;
  width: 100px;
  height: 20px;
  animation: 1.5s ease-in-out infinite pulse;
  display: inline-block;
}

/*# sourceMappingURL=index.e1eb445f.css.map */
