.buttons {
  align-items: center;
  border-radius: 24px;
  display: inline-flex;
  gap: 8px;
  justify-content: center;
  position: relative;
}

.buttons .instance-node {
  height: 20px !important;
  position: relative !important;
  width: 20px !important;
}

.buttons .label {
  font-family: var(--typography-action-font-family);
  font-size: var(--typography-action-font-size);
  font-style: var(--typography-action-font-style);
  font-weight: var(--typography-action-font-weight);
  letter-spacing: var(--typography-action-letter-spacing);
  line-height: var(--typography-action-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.buttons.disabled {
  background-color: var(--neutral-50);
}

.buttons.error.focus {
  background-color: var(--reddarker);
}

.buttons.off.off {
  padding: 8px 16px;
}

.buttons.hover.important {
  background-color: var(--neutral-800);
}

.buttons.hover.primary {
  background-color: var(--bluedarker);
}

.buttons.hover.blank {
  background-color: var(--neutral-50);
}

.buttons.hover.secondary {
  background-color: var(--neutral-0);
  border: 1px solid;
  border-color: var(--neutral-900);
}

.buttons.on.off {
  padding: 8px 16px 8px 10px;
}

.buttons.normal.secondary {
  background-color: var(--neutral-0);
  border: 1px solid;
  border-color: var(--neutral-200);
}

.buttons.blank.focus {
  background-color: var(--neutral-100);
}

.buttons.normal.primary {
  background-color: var(--bluenormal);
}

.buttons.important.normal {
  background-color: var(--neutral-900);
}

.buttons.important.focus {
  background-color: var(--neutral-700);
}

.buttons.on.off {
  padding: 8px 10px 8px 16px;
}

.buttons.focus.primary {
  background-color: var(--bluedarker);
  border: 1px solid;
  border-color: var(--bluenormal);
}

.buttons.error.normal {
  background-color: var(--neutral-0);
  border: 1px solid;
  border-color: var(--rednormal);
}

.buttons.focus.secondary {
  background-color: var(--neutral-50);
}

.buttons.hover.error {
  background-color: var(--rednormal);
}

.buttons.on.on {
  padding: 8px 10px;
}

.buttons.on.on.focus.secondary {
  border: 1px solid;
  border-color: var(--neutral-0);
}

.buttons.disabled .label {
  color: var(--neutral-300);
}

.buttons.secondary .label {
  margin-top: -0.5px;
}

.buttons.important .label {
  margin-top: -1px;
}

.buttons.primary .label {
  margin-top: -1px;
}

.buttons.blank .label {
  margin-top: -0.5px;
}

.buttons.error.focus .label {
  color: var(--neutral-0);
  margin-top: -1px;
}

.buttons.blank.normal .label {
  color: var(--neutral-900);
}

.buttons.hover.important .label {
  color: var(--neutral-0);
}

.buttons.hover.primary .label {
  color: var(--neutral-0);
}

.buttons.hover.blank .label {
  color: var(--neutral-900);
}

.buttons.hover.secondary .label {
  color: var(--neutral-900);
}

.buttons.normal.secondary .label {
  color: var(--neutral-900);
}

.buttons.blank.focus .label {
  color: var(--neutral-900);
}

.buttons.normal.primary .label {
  color: var(--neutral-0);
}

.buttons.important.focus .label {
  color: var(--neutral-0);
}

.buttons.important.normal .label {
  color: var(--neutral-0);
}

.buttons.focus.primary .label {
  color: var(--neutral-0);
}

.buttons.error.normal .label {
  color: var(--rednormal);
  margin-top: -0.5px;
}

.buttons.focus.secondary .label {
  color: var(--neutral-900);
}

.buttons.error.disabled .label {
  margin-top: -0.5px;
}

.buttons.hover.error .label {
  color: var(--neutral-0);
  margin-top: -1px;
}
