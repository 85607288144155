.index {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.index .trade {
  background-color: #ffffff;
  overflow: hidden;
  position: relative;
}

.index .overlap-group {
  position: absolute;
  top: 0;
}

.index .liquidity-sources {
  height: 500px;
  object-fit: cover;
  position: absolute;
  width: 1133px;
}

.index .wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 24px;
  left: 394px;
  position: absolute;
  top: 521px;
  width: 345px;
}

.index .top {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 808px;
  position: relative;
}

.index .frame {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}

.index .heading-arbitrum-wrapper {
  align-items: flex-end;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 12px;
  justify-content: center;
  position: relative;
}

.index .heading-arbitrum {
  color: #121315;
  font-family: "Inter", Helvetica;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 38px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.index .div {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #e4e4e7;
  border-radius: 26px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.index .frame-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  padding: 17px 32px;
  position: relative;
  width: 100%;
}

.index .text-wrapper {
  color: #22262a;
  font-family: "Inter", Helvetica;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.18px;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.index .frame-3 {
  align-items: center;
  align-self: stretch;
  background-color: #f5f5f6;
  border-radius: 10px;
  display: flex;
  gap: 10px;
  height: 36px;
  padding: 0px 10px;
  position: relative;
  width: 100%;
}

.index .svgexport {
  height: 16px;
  position: relative;
  width: 16px;
}

.index .text-wrapper-2 {
  color: #4c4c4c;
  font-family: "Inter", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.14px;
  line-height: 24px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.index .line {
  align-self: stretch;
  height: 1px;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.index .frame-4 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 12px 32px;
  position: relative;
  width: 100%;
}

.index .text-wrapper-3 {
  color: #5e6773;
  font-family: "Inter", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.14px;
  line-height: 14px;
  margin-top: -1px;
  position: relative;
  width: 48px;
}

.index .text-wrapper-4 {
  color: #5e6773;
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.14px;
  line-height: 14px;
  margin-top: -1px;
  position: relative;
}

.index .text-wrapper-5 {
  color: #5e6773;
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.14px;
  line-height: 14px;
  margin-top: -1px;
  position: relative;
}

.index .frame-5 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 14px 32px;
  position: relative;
  width: 100%;
}

.index .text-wrapper-6 {
  color: #17171c;
  font-family: "Inter", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  width: 48px;
}

.index .text-wrapper-7 {
  color: #17171c;
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
}

.index .text-wrapper-8 {
  color: #5e6773;
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
}

.index .frame-6 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 6px;
  padding: 14px 32px;
  position: relative;
  width: 100%;
}

.index .img {
  height: 36px;
  position: relative;
  width: 36px;
}

.index .div-wrapper {
  align-items: center;
  background-color: #0077ff1a;
  border: 1px solid;
  border-color: #0077ff;
  border-radius: 10px;
  display: flex;
  gap: 10px;
  height: 36px;
  justify-content: center;
  position: relative;
  width: 36px;
}

.index .text-wrapper-9 {
  color: #17171c;
  font-family: "Inter", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.index .frame-7 {
  align-items: center;
  background-color: #3838520d;
  border-radius: 10px;
  display: flex;
  gap: 10px;
  height: 36px;
  justify-content: center;
  position: relative;
  width: 36px;
}

.index .frame-8 {
  align-items: center;
  background-color: #38385206;
  border-radius: 10px;
  display: flex;
  gap: 10px;
  height: 36px;
  justify-content: center;
  position: relative;
  width: 36px;
}

.index .frame-9 {
  height: 36px;
  margin-right: -7px;
  position: relative;
  width: 36px;
}

.index .rectangle {
  background: radial-gradient(50% 50% at 50% 50%, rgb(255, 255, 255) 62.5%, rgba(255, 255, 255, 0) 100%);
  height: 391px;
  left: 360px;
  position: absolute;
  top: 138px;
  width: 414px;
}

.index .frame-10 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 40px;
  position: absolute;
}

.index .frame-11 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.index .frame-12 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.index .text-wrapper-10 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    180deg,
    rgb(0, 102.28, 219.43) 0%,
    rgba(13.34, 110.51, 221.81, 0.96) 37.5%,
    rgba(190.63, 219.93, 253.49, 0.38) 100%
  );
  background-clip: text;
  color: transparent;
  font-family: "Aeonik-Medium", Helvetica;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.2px;
  line-height: 38px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  text-fill-color: transparent;
  white-space: nowrap;
  width: fit-content;
}

.index .text-wrapper-11 {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-weight: 500;
  line-height: 80px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.index .p {
  color: #5e6773;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.16px;
  line-height: 25px;
  position: relative;
  text-align: center;
}

.index .button {
  all: unset;
  align-items: center;
  background-color: #121315;
  border-radius: 26px;
  box-shadow: var(--small-elements);
  box-sizing: border-box;
  display: inline-flex;
  height: 40px;
  justify-content: flex-end;
  overflow: hidden;
  padding: 10px 16px;
  position: relative;
  cursor: pointer;
}

.index .text-wrapper-12 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.14px;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.index .header {
  align-items: center;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
}

.index .logo-instance {
  height: 17px !important;
  left: 0 !important;
  position: absolute !important;
  top: 2px !important;
  width: 25px !important;
}

.index .frame-13 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 12px;
  justify-content: center;
  position: relative;
}

.index .button-2 {
  all: unset;
  align-items: center;
  background-color: #ffffff;
  border-radius: 26px;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  height: 40px;
  justify-content: flex-end;
  overflow: hidden;
  padding: 10px 16px;
  position: relative;
  cursor: pointer;
}

.index .text-wrapper-13 {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.index .right {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  position: relative;
  width: 116px;
}

.index .button-3 {
  all: unset;
  align-items: center;
  background-color: #121315;
  border-radius: 26px;
  box-shadow: var(--small-elements);
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  height: 40px;
  justify-content: flex-end;
  overflow: hidden;
  padding: 10px 16px;
  position: relative;
  cursor: pointer;
}

.index .logo-2 {
  height: 20px !important;
  width: 96.67px !important;
}

.index .logo-3 {
  height: 16px !important;
  left: 30px !important;
  top: 2px !important;
  width: 65px !important;
}

.index .logo-4 {
  height: 14px !important;
  left: 0 !important;
  position: absolute !important;
  top: 2px !important;
  width: 20px !important;
}

.index .right-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}

.index .buttons-instance {
  flex: 0 0 auto !important;
}

.index .button-4 {
  flex: 0 0 auto;
  margin-bottom: -3px;
  margin-right: -2px;
  margin-top: -1px;
  position: relative;
}

.index .wrapper-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 24px;
  left: 248px;
  position: absolute;
  top: 494px;
  width: 638px;
}

.index .frame-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 808px;
  position: relative;
  width: 100%;
}

.index .frame-14 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 17px 32px;
  position: relative;
  width: 100%;
}

.index .text-wrapper-14 {
  color: #22262a;
  font-family: "Inter", Helvetica;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.18px;
  line-height: 24px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.index .frame-15 {
  align-items: center;
  background-color: #f5f5f6;
  border-radius: 10px;
  display: flex;
  gap: 10px;
  height: 36px;
  padding: 0px 10px;
  position: relative;
  width: 310px;
}

.index .text-wrapper-15 {
  color: #5e6773;
  font-family: "Inter", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.14px;
  line-height: 14px;
  margin-top: -1px;
  position: relative;
  width: 120px;
}

.index .text-wrapper-16 {
  color: #17171c;
  font-family: "Inter", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  width: 120px;
}

.index .frame-16 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 14px 32px;
  position: relative;
  width: 100%;
}

.index .rectangle-2 {
  background: radial-gradient(50% 50% at 50% 50%, rgb(255, 255, 255) 62.5%, rgba(255, 255, 255, 0) 100%);
  height: 308px;
  left: 488px;
  position: absolute;
  top: 132px;
  width: 545px;
}

.index .frame-17 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 40px;
  left: 483px;
  position: absolute;
  top: 168px;
}

.index .text-wrapper-17 {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 64px;
  font-weight: 500;
  letter-spacing: -0.64px;
  line-height: 80px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.index .text-wrapper-18 {
  color: #5e6773;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 49px;
  letter-spacing: -0.16px;
  line-height: 25px;
  position: relative;
  text-align: center;
  width: 474px;
}

.index .wrapper-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 24px;
  left: 100px;
  position: absolute;
  top: 510px;
  width: 1240px;
}

.index .frame-18 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 8px;
  position: relative;
}

.index .ellipse {
  background-color: #d9d9d9;
  border-radius: 8px;
  box-shadow: 0px 1px 2px #00000040;
  height: 16px;
  position: relative;
  width: 16px;
}

.index .frame-19 {
  align-items: center;
  border-color: #f1f2f4;
  border-top-style: solid;
  border-top-width: 1px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  position: absolute;
}

.index .frame-20 {
  align-items: center;
  height: 33px;
  position: relative;
}

.index .frame-21 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 16px;
  position: relative;
}

.index .text-wrapper-19 {
  color: #5e6773;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.16px;
  line-height: 25px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
/* CSS */
.page-number {
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}

.page-number.active {
  color: #0077FF; /* Text color for active page */
  border: 1px solid #0077FF; /* Border for active page */
  background-color: rgba(0, 119, 255, 0.1); /* Background color for active page with 10% opacity */
}
.search-input {
  border: none;
  background-color: #F5F5F6; /* Background color with 10% opacity */
  outline: none; /* Removes the outline to avoid a box appearance on focus */
  width: 100%; /* Ensures it fills the container */
  box-sizing: border-box; /* Ensures padding does not add to the width */
}

.search-input::placeholder {
  color: #000000; /* Placeholder text color */
  opacity: 1; /* Ensures the placeholder text is fully visible */
}
.divider {
  background-color: #E4E4E7; /* Choose a color for the divider */
  width: 100%; /* Ensures it stretches across the container */
  align-self: stretch;
  height: 1px;
  object-fit: cover;
  position: relative;
}


