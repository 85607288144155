.popup {
  position: fixed; /* Change from flex positioning to fixed */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
}

.popup .frame-wrapper {
  height: 458px;
  width: 418px;
}

.popup .frame {
  align-items: center;
  background-color: #ffffff;
  border-radius: 26px;
  box-shadow: 0px 2px 4px #00000005, 0px 20px 70px #0000000f, 0px 2px 12px #00000008;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 418px;
}

.popup .div {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 24px;
  position: relative;
  width: 100%;
}

.popup .text-wrapper {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.popup .img {
  height: 16px;
  position: relative;
  width: 16px;
  cursor: pointer;
}

.popup .frame-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  padding: 16px 24px 24px;
  position: relative;
  width: 100%;
}

.popup .frame-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
  width: 100%;
}

.popup .frame-4 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
  position: relative;
}

.popup .text-wrapper-2 {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.popup .input {
  align-self: stretch;
  background-color: #f5f5f6;
  border: 0;
  border-radius: 10px;
  color: #000000;
  flex: 0 0 auto;
  font-family: "Inter", Helvetica;
  font-size: 14px;
  font-weight: 400;
  height: 36px;
  letter-spacing: 0;
  line-height: 16px;
  padding: 0px 12px;
  position: relative;
  white-space: nowrap;
  width: 100%;
}

.popup .p {
  align-self: stretch;
  color: #31353c;
  font-family: "Inter", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18px;
  position: relative;
}

.popup .line {
  align-self: stretch;
  height: 1px;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.popup .div-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  position: relative;
  width: 100%;
  cursor: pointer;
}

.popup .frame-5 {
  align-items: center;
  align-self: stretch;
  background-color: #17171c;
  border-radius: 50px;
  box-shadow: 0px 1px 2px #00000033;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 52px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.popup .text-wrapper-3 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}



/* Button styles */
.socials button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  gap: 14px;
  width: 179px;
  height: 64px;
  background: #FFFFFF;
  border: 1px solid #E2E4E9;
  box-shadow: 0px 1px 2px rgba(228, 229, 231, 0.24);
  border-radius: 16px;
  box-sizing: border-box;
  flex-grow: 1;
  cursor: pointer;
}

.socials button :disabled {
  background: #a2a2a2;
}

/* Icon styles */
.socials .outline {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 8px;
  width: 40px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #E2E4E9;
  box-shadow: 0px 1px 2px rgba(228, 229, 231, 0.24);
  border-radius: 999px;
}

/* Image within the icon */
.socials .outline img {
  width: 24px;
  height: 24px;
  object-fit: cover; /* Adjust as needed */
}

/* Text next to the icon */
.socials h2 {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #0A0D14;
  margin: 0; /* Reset default margin */
}
.socials {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px;
  gap: 20px;
}

.socials h1 {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
}
.socials-button {
  display: inline-flex; 
  flex-direction: row;
  align-items: center;
  padding: 12px;
  gap: 14px;
  width: 179px;
  height: 64px;
  background: #FFFFFF;
  border: 1px solid #E2E4E9;
  box-shadow: 0px 1px 2px rgba(228, 229, 231, 0.24);
  border-radius: 16px;
  box-sizing: border-box;
  text-decoration: none; 
  color: black; 
  cursor: pointer;
}

.socials-button:disabled {
  background: #c5c5c5;
}

.socials-button:hover {
  background: #f5f5f6;
}

.socials-button h2 {
  margin: 0; 
  font-size: 14px; 
  color: inherit; 
}
.socials-button-clicked {
  pointer-events: none; 
  cursor: default;
  border: solid 2px #00ba6d; 
}

.social-title{
  font-family: Inter;
  font-size: 14px;
  width: 100%;
  padding-left: 24px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  }